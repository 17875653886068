import { Component, OnInit, } from '@angular/core';
import { Services } from '../services/services';

@Component({
  selector: 'app-left-tab',
  templateUrl: '../views/elements/left-tab.component.html'
})
export class LeftTabComponent implements OnInit {
  toggle: boolean
  val: string = ""

  constructor(private _service: Services) {
    if (localStorage.getItem("active_option") !== null) {
      this.val = localStorage.getItem("active_option")
    }
  }

  ngOnInit() {
    this._service.toggle_class.subscribe((data) => {
      this.toggle = data
    });
  }






}
