import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Services } from '../services/services';
import { Alert } from '../Helpers/alert';

@Component({
  selector: 'app-paypal-usage',
  templateUrl: '../views/paypal-usage.component.html'
})
export class PaypalUsageComponent implements OnInit {
  isLoaderVisible=false;
  constructor(private router: Router, private service: Services, private alert: Alert) {
  }

  ngOnInit() {
    this.isLoaderVisible=true;
    var url = this.router.url.substr(1, this.router.url.length)
    this.service.payment_process(url).subscribe(
      response => {
        if(response.data.current_status == 'active'){
          this.alert.alert_process_msg("Thank you for subscribing to HVAC Maintenance Tracker. You are now active.");
          localStorage.setItem("subscription_status", '1');
          this.isLoaderVisible=false;
          this.router.navigate(['/customer']);
        }else{
          this.alert.alert_process_msg("Your request has been accepted and is under process by paypal. Please try after 30 minutes.");
          localStorage.clear();
          localStorage.setItem("subscription_status", '3');
          this.isLoaderVisible=false;
          this.router.navigate(['/login']);
        }
      },
      error => {
        this.alert.showErrorMessage("Something went wrong please try again.");
        this.isLoaderVisible=false;
        this.router.navigate(['/addsubscription'])
      })
  }
}
