import { FormGroup } from '@angular/forms';

export function validateEmail(controls) {
  // Create a regular expression
  const regExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  // Test email against regular expression
  if (regExp.test(controls.value)) {
    return null; // Return as valid email
  } else {
    return { 'validateEmail': true } // Return as invalid email
  }
}

export function validatePhnNumber(value: string) {
  const regExp = new RegExp(/^[0-9]$/)
  if (regExp.test(value)) {
    return true
  }
  else
    return false
}

export function validatePhone(controls) {
  // Create a regular expression
  const regExp = new RegExp(/^([\d]{3})[(\s)(\-)]{0,1}[\d]{3}[(\s)(\-)]{0,1}[\d]{4}$/);
  const regExp1 = new RegExp(/^$/)
  // Test phone against regular expression
  if (controls.value != null) {
    if (regExp.test(controls.value) || regExp1.test(controls.value)) {
      return null; // Return as valid phone
    } else {
      return { 'validatePhone': true } // Return as invalid phone
    }
  }
}

export function validateExt(control) {
  const regExp = new RegExp(/^[0-9]{0,4}$/)
  if (control.value == "" || control.value == null) {
    return false
  }
  else if (regExp.test(control.value)) {
    return false
  }
  else {
    return { 'validateExt': true }
  }
}

export function matchingPasswords(passwordKey: string, password_confirmationkey: string) {
  return (group: FormGroup): { [key: string]: any } => {
    let password = group.controls[passwordKey];
    let password_confirmation = group.controls[password_confirmationkey];

    if (password.value !== password_confirmation.value) {
      return {
        mismatchedPasswords: true
      };
    }
  }
}

export function passwordsLength(controls) {
  if (controls.value.length < 6) {
    return {
      'passwordsLength': true
    }
  }
}

export function isvalidDate(day, month, year) {
  var date = new Date();
  date.setFullYear(year);
  date.setMonth(month - 1);
  date.setDate(day);
  if ((date.getFullYear() == year) && (date.getMonth() == month - 1) && (date.getDate() == day)) {
    return true;
  }
  else {
    return false;
  }
}


// export function ismorethantoday(day,month,year){
//   var date = new Date();
//   var today=new Date(Date.now())


//    if(today.getMonth()+1==(month)){
//     if(day==null || day== ""){
//           day = today.getDate()
//     }
//   }
//   else{
//     if(day==null || day== ""){
//         day = 1
//       }
//   }

//   date.setFullYear(year);
//   date.setMonth(month-1);
//   date.setDate(day)
//         if (date!<today){
//             return true;
//         }
//         else{
//             return false;
//         }

// }