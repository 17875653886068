import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxPayPalModule } from 'ngx-paypal';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Alert } from './Helpers/alert';
import { AuthGuard } from './services/auth.guard';
import { Services } from './services/services';
import { RegisterComponent } from './controllers/register.component';
import { CustomerComponent } from './controllers/customer.component';
import { LogInComponent } from './controllers/log-in.component';
import { SubscriptionComponent } from './controllers/subscription.component';
import { MyProfileComponent } from './controllers/my-profile.component';
import { ReportsComponent } from './controllers/reports.component';
import { ContractValueComponent } from './controllers/contract-value.component';
import { AddUserSubscriptionComponent } from './controllers/add-user-subscription.component';
import { RightTabComponent } from './controllers/right-tab.component';
import { BreadcrumComponent } from './controllers/breadcrum.component';
import { LeftTabComponent } from './controllers/left-tab.component';
import { HeaderComponent } from './controllers/header.component';
import { FooterComponent } from './controllers/footer.component';
import { AppRoutingModule, routes } from './app-routing.module';
import { AddCustomerComponent } from './controllers/add-customer.component';
import { InterceptService } from './services/intercept.service';
import { CustomerDetailComponent } from './controllers/customer-detail.component';
import { NgDatepickerModule } from 'ng2-datepicker';
import { AddPropertyComponent } from './controllers/add-property.component';
import { AddEquipmentComponent } from './controllers/add-equipment.component';
import { ShowEquipmentImageComponent } from './controllers/show-equipment-image.component';
import { AgmCoreModule } from '@agm/core';
import { PaginationComponent } from './controllers/pagination.component';
import { ShowPropertyImageComponent } from './controllers/show-property-image.component';
import { ResetpasswordComponent } from './controllers/resetpassword.component';
import { MatDialogModule } from "@angular/material";
import { PagerService } from './services/pager.service';
import { NotificationComponent } from './controllers/notification.component';
import { PaymentPopupComponent } from './payment-popup/payment-popup.component';
import { PaypalUsageComponent } from './controllers/paypal-usage.component';
import { PaymentComponent } from './controllers/payment.component';
import { DecimalPipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    LogInComponent,
    PaymentComponent,
    RegisterComponent,
    CustomerComponent,
    HeaderComponent,
    FooterComponent,
    SubscriptionComponent,
    LeftTabComponent,
    ReportsComponent,
    ContractValueComponent,
    MyProfileComponent,
    RightTabComponent,
    BreadcrumComponent,
    AddUserSubscriptionComponent,
    AddCustomerComponent,
    CustomerDetailComponent,
    AddPropertyComponent,
    PaginationComponent,
    AddEquipmentComponent,
    ShowEquipmentImageComponent,
    ShowPropertyImageComponent,
    ResetpasswordComponent,
    NotificationComponent,
    PaymentPopupComponent,
    PaypalUsageComponent
  ],
  imports: [
    MatDialogModule,
    BrowserModule,
    NgbModule.forRoot(),
    NgDatepickerModule,
    RouterModule.forRoot(routes),
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    NgxPayPalModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyChPLN8RuhxtOgtBhQGeX19-F7xvyxlpo4'
    })
  ],
  providers: [
    PagerService,
    DecimalPipe,
    NgbActiveModal,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    Services,
    Alert,
    AuthGuard,
  ],
  bootstrap:
    [
      AppComponent
    ],
  entryComponents:
    [
      PaymentPopupComponent,
      AddCustomerComponent,
      AddPropertyComponent,
      AddEquipmentComponent,
      ShowEquipmentImageComponent,
      ShowPropertyImageComponent
    ]
})
export class AppModule { }