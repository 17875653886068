import { Component, OnInit } from '@angular/core';
import { Services } from '../services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Alert } from '../Helpers/alert';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-user-subscription',
  templateUrl: '../views/add-user-subscription.component.html',

})
export class AddUserSubscriptionComponent implements OnInit {
  plans_array = [];
  isLoaderVisible = false;
  url: any;
  constructor(
    private router: Router,
    private _service: Services,
    private modalService: NgbModal,
    private alert_message: Alert) {
    this.subscribeSubscriptionPlan(this);
  }

  ngOnInit() {
    this.plans_array = []
    this.isLoaderVisible = true
    this._service.loadPackageList('subscriptionlist').subscribe(
      (response) => {

        this.isLoaderVisible = false
        this.plans_array = response.data;

      },
      error => {
        this.isLoaderVisible = false
        if (error.status !== '401') {
          this.alert_message.showErrorMessage("Something went wrong.Please try again.")
        }
      }
    );
  }


  onClick(plan) {
    this.isLoaderVisible = true;
    // let ngbModalOptions: NgbModalOptions = {
    //   backdrop: 'static',
    //   keyboard: false
    // };
    if (plan.price == '0') {
      // localStorage.setItem('selected_plan', JSON.stringify(plan));
      this._service.subscribed('updateusersubscription', {
        'plan_id': plan.id,
        'paypal_profile_id': '',
        'paypal_payment_id': ''
      }).subscribe(
        (response) => {
          this.isLoaderVisible = false;
          this.alert_message.alert_msg(response.message);
          localStorage.setItem('subscription_status', '1');
          this.router.navigate(['/customer']);
        },
        error => {
          this.isLoaderVisible = false;
          if (error.status !== '401') {
            this.alert_message.showErrorMessage('Something went wrong please try again!');
          }
        }
      );


    }
    else {
      this._service.get_url('createbillingagreement', { subs_plan_id: plan.id }).subscribe(
        response => {
          this.url = response.data.approvalUrl;
          window.location.href = this.url;
          this.isLoaderVisible = false;
          // localStorage.setItem("Url",this.url);
          // const modalRef = this.modalService.open(PaymentPopupComponent, ngbModalOptions);
        },
        error => {
          this.isLoaderVisible = false;
          if (error.status !== '401') {
            this.alert_message.showErrorMessage('Something went wrong please try again!');
          }
        })

    }
  }
  subscribeSubscriptionPlan(instance) {
    this._service.subscriptionPlanEmitter.subscribe(function (value) {
      localStorage.removeItem('selected_plan');
      localStorage.setItem('subscription_status', '1');
      this.router.navigate(['/customer']);
    });
  }

}
