import { Component, OnInit } from '@angular/core';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Services } from '../services/services';
import { Alert } from '../Helpers/alert';
@Component({
  selector: 'app-payment',
  templateUrl: '../views/payment.component.html',

})
export class PaymentComponent implements OnInit {
  selected_plan: { id: string, price: number };
  detail: any;
  errors: any;
  isLoaderVisible = false
  public payPalConfig?: PayPalConfig;
  constructor(private active_modal: NgbActiveModal, private user_service: Services, private alert_message: Alert) {

  }
  ngOnInit(): void {
    this.isLoaderVisible = true
    this.selected_plan = JSON.parse(localStorage.getItem('selected_plan'));
    this.initConfig();

  }

  closeModal() {
    this.active_modal.close('Modal Closed');
  }

  private initConfig(): void {

    this.payPalConfig = new PayPalConfig(PayPalIntegrationType.ClientSideREST, PayPalEnvironment.Production, {
      commit: true,
      client: {
        production: 'AcFKCUWB6Wfa172HbF9GJ3JIWGvSwIVLydGxWUjDt_Ia9rY6DmngBZhLg-4vnkgzKKpLcp18CDWAGW17',
      },
      button: {
        label: 'paypal',

      },
      onPaymentComplete: (data, actions) => {
        // console.log('OnPaymentComplete');
        this.detail = {
          'plan_id': this.selected_plan.id,
          'paypal_profile_id': data.payerID,
          'paypal_payment_id': data.paymentID
        };
        this.user_service.subscribed('updateusersubscription', this.detail).subscribe(
          (response) => {
            localStorage.setItem("subscription_status", '1')
            this.user_service.subscriptionPlanEmitter.emit(this.selected_plan.id);
            this.alert_message.alert_msg(response.message);
            this.active_modal.close();
          },
          error => {
            if (error.status !== '401') {
              this.alert_message.showErrorMessage('Something went wrong please try again!');
            }
            this.active_modal.close();
          }
        );
      },
      onCancel: (data, actions) => {
        this.alert_message.alert_msg('Something went wrong, Please use any other card.');
        this.active_modal.close();
      },
      onError: (err) => {
        // console.log('OnError');
        this.alert_message.showErrorMessage('Something went wrong please try again!');
        this.active_modal.close();
      },
      transactions: [{
        amount: {
          currency: 'USD',
          total: this.selected_plan.price
        }
      }]


    });
    this.isLoaderVisible = false

  }
}
