import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable()
export class Services {
    api_url = environment.apiUrl;
    HttpUploadOptions = {
        headers: new HttpHeaders({})
    }
    loggedInEmitter: EventEmitter<any>;
    // loggedOutEmitter: EventEmitter<any>;
    subscriptionPlanEmitter: EventEmitter<any>;
    addEquipmentEmitter: EventEmitter<any>;
    addPropertyEmitter: EventEmitter<any>;
    addCustomerEmitter: EventEmitter<any>;
    userName: EventEmitter<any>;
    toggle_class: EventEmitter<any>;
    notification: EventEmitter<any>;
    editprofileimage: EventEmitter<any>;
    editprofile: EventEmitter<any>;
    registerusername: EventEmitter<any>;
    notification_flag: EventEmitter<any>;
    urlemitter:EventEmitter<any>;
    // statusemitter:EventEmitter<any>;
    constructor(private http: HttpClient) {
        this.loggedInEmitter = new EventEmitter();
        //this.loggedOutEmitter = new EventEmitter();
        this.subscriptionPlanEmitter = new EventEmitter();
        this.userName = new EventEmitter();
        this.toggle_class = new EventEmitter();
        this.addEquipmentEmitter = new EventEmitter();
        this.addPropertyEmitter = new EventEmitter();
        this.addCustomerEmitter = new EventEmitter();
        this.notification = new EventEmitter();
        this.notification_flag = new EventEmitter();
        this.editprofileimage = new EventEmitter();
        this.editprofile = new EventEmitter();
        this.registerusername = new EventEmitter();
        this.urlemitter=new EventEmitter();
        // this.statusemitter=new EventEmitter();
    }

    equipment(url, detail): Observable<any> {
        return this.http.post(this.api_url + url, detail)
    }

    loginService(url, users): Observable<any> {
        return this.http.post(this.api_url + url, users)
    }
    signupService(url, users): Observable<any> {
        return this.http.post(this.api_url + url, users)
    }
    forgotPassword(url, email): Observable<any> {
        return this.http.post(this.api_url + url, { email: email })
    }
    resetPassword(url, token): Observable<any> {
        return this.http.post(this.api_url + url, { _token: token })
    }
    response_resetPassword(url, detail): Observable<any> {

        return this.http.post(this.api_url + url, detail)
    }
    edit_user_info(url): Observable<any> {
        return this.http.post(this.api_url + url, JSON.stringify({ content: 'any' }))
    }
    update_user_info(url, details): Observable<any> {
        return this.http.post(this.api_url + url, details)
    }
    change_password(url, pwd_details): Observable<any> {
        return this.http.post(this.api_url + url, pwd_details)
    }
    change_logo(url, userimage): Observable<any> {
        return this.http.post(this.api_url + url, userimage, this.HttpUploadOptions)
    }
    logout(url): Observable<any> {
        return this.http.post(this.api_url + url, JSON.stringify({ content: 'any' }))
    }
    load_state_list(url): Observable<any> {
        return this.http.get(this.api_url + url)
    }
    loadPackageList(url): Observable<any> {
        return this.http.get(this.api_url + url)
    }
    subscribed(url, details): Observable<any> {
        return this.http.post(this.api_url + url, details)
    }
    get_customer(url): Observable<any> {
        return this.http.get(this.api_url + url)
    }
    add_customer(url, details): Observable<any> {
        return this.http.post(this.api_url + url, details)
    }
    customer_detail(url): Observable<any> {
        return this.http.get(this.api_url + url)
    }
    edit_cusomer(url, detail, customerid): Observable<any> {
        detail.customerid = customerid
        return this.http.post(this.api_url + url, detail)
    }
    add_property(url, detail): Observable<any> {
        return this.http.post(this.api_url + url, detail)
    }
    property(url, detail): Observable<any> {
        return this.http.post(this.api_url + url, detail)
    }
    get_equipments(url, detail): Observable<any> {
        return this.http.post(this.api_url + url, detail)
    }
    get_image(url, image): Observable<any> {
        return this.http.post(this.api_url + url, image)
    }
    add_equipment(url, details): Observable<any> {
        return this.http.post(this.api_url + url, details, this.HttpUploadOptions)
    }
    delete_customer(url, id): Observable<any> {
        return this.http.post(this.api_url + url, id);
    }
    delete_property(url, ids): Observable<any> {
        return this.http.post(this.api_url + url, ids);
    }
    delete_Equipments(url, ids): Observable<any> {
        return this.http.post(this.api_url + url, ids);
    }
    delete_Equipment_image(url, ids): Observable<any> {
        return this.http.post(this.api_url + url, ids);
    }
    delete_email(url, emailid): Observable<any> {
        return this.http.post(this.api_url + url, emailid);
    }
    delete_phone(url, phone): Observable<any> {
        return this.http.post(this.api_url + url, phone);
    }
    markcompleted(url, detail): Observable<any> {
        return this.http.post(this.api_url + url, detail)
    }
    delete_filter(url, detail): Observable<any> {
        return this.http.post(this.api_url + url, detail)
    }
    get_contract_value(url): Observable<any> {
        return this.http.get(this.api_url + url)
    }
    show_report(url, detail): Observable<any> {
        return this.http.post(this.api_url + url, detail)
    }
    download_report(url, detail): Observable<any> {
        return this.http.post(this.api_url + url, detail, { responseType: 'text' })
    }
    get_notification(url): Observable<any> {
        return this.http.get(this.api_url + url)
    }
    changenotificationstatus(url, ids): Observable<any> {
        return this.http.post(this.api_url + url, ids)
    }
    showpropertyimage(url, detail): Observable<any> {
        return this.http.post(this.api_url + url, detail)
    }
    showContract(url, detail): Observable<any> {
        return this.http.post(this.api_url + url, detail)
    }
    downloadContract(url): Observable<any> {
        return this.http.get(this.api_url + url)
    }
    deleteContract(url, detail): Observable<any> {
        return this.http.post(this.api_url + url, detail)
    }
    searchText(url, value): Observable<any> {
        return this.http.post(this.api_url + url, value)
    }
    changeNotificationSettings(url, value): Observable<any> {
        return this.http.post(this.api_url + url, value)
    }
    uploasCsv(url, value): Observable<any> {
        return this.http.post(this.api_url + url, value)
    }
    getadsdetail(url): Observable<any> {
        return this.http.get(this.api_url + url)
    }
    get_url(url,subs_plan_id):Observable<any>{
        return this.http.post(this.api_url + url,subs_plan_id)
    }
    payment_process(url):Observable<any>{
        return this.http.get(this.api_url+url);
    }
}
