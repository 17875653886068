import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Services } from '../services/services';
import { PipeTransform } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AddCustomerComponent } from './add-customer.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Alert } from '../Helpers/alert';
import { getHours } from 'date-fns';
import { PagerService } from '../services/pager.service';

@Component({
  selector: 'app-customer',
  templateUrl: '../views/customer.component.html'

})
export class CustomerComponent implements OnInit, PipeTransform {
  myFile: string;
  file: File
  zoom: number = 8;
  previous: any
  loopStartingIndex: number
  totalRecords: number
  page = 1
  pager: any = {};
  hasSelectedIds: boolean
  _message = 'Customer';
  delete = false;
  response: any;
  email: string;
  baseurl: string;
  prevurl: any;
  i = 0;
  item = 0;
  totalpage: number;
  total_customers: number;
  customerlistlength: number;
  total_searchresult: number = 0;
  perpagerecord: number = 0
  array = new Array();
  customerid: number;
  curr_page = 1;
  selected_ids = new Array();
  isLoaderVisible = false
  lat: any;
  lng: any;
  centerLat: number;
  centerLng: number;
  customer_list: any;
  customer_list1: any;
  search_list: any
  searchtxt = "";
  search_flag = false
  show_map = false
  @ViewChild('closeBtn') closeBtn: ElementRef;
  collection = [];
  constructor(private pagerService: PagerService, private alert: Alert, private _service: Services, private modalService: NgbModal, private router: Router) {
    for (let i = 1; i <= 100; i++) {
      this.collection.push(`item ${i}`);
    }
    this.subscribeAddCustomer(this);
  }
  transform(value: number): number {
    return Math.ceil(value);
  }

  ngOnInit() {
    this.notification()
    this.isLoaderVisible = true
    this.baseurl = '/customer';
    this.getdata();

  }

  notification() {
    let notification_date = localStorage.getItem("notification_date")
    //let notification_date=addHours(Date.now(),2)
    let current_date = new Date(Date.now());
    if (notification_date != null || !notification_date) {
      var timeDiff = parseFloat(((current_date.getTime() - new Date(notification_date).getTime()).toString()));
      timeDiff = timeDiff / (60 * 60 * 1000)
    }
    if (timeDiff > getHours(Date.now())) {
      this._service.get_notification('notification').subscribe(
        (response) => {
          this._service.notification.emit(response.data)
          localStorage.setItem("notification_date", new Date(Date.now()).toString())
        },
        (error) => { console.log(error) })
    }
  }
  getdata() {
    this._service.get_customer('customerslist').subscribe(
      (response) => {
        // console.log(response)
        this.isLoaderVisible = false
        this.customerlistlength = response.data.totalcustomers;
        this.total_customers = this.customerlistlength
        this.curr_page = parseInt(response.data.currentpage);
        this.perpagerecord = parseInt(response.data.perpagerecord)
        this.totalpage = this.transform(response.data.totalcustomers / response.data.perpagerecord);
        for (this.i = 0; this.i < this.totalpage; this.i++) {
          this.array.push(this.i + 1);
        }
        this.setDate(response);
      },
      error => {
        this.isLoaderVisible = false
        if (error.status !== '401') {
          console.log(error);
        }
      }
    );
  }
  setDate(response) {

    this.response = response.data;
    this.response.customerlist.forEach(element => {
      this.i = this.i + 1;
    });

    this.customer_list = this.response.customerlist;
    this.customer_list1 = this.customer_list;
    if (this.customer_list1.length > 0) {
      for (let element of this.customer_list1) {
        if (element.customer_properties.length > 0) {
          this.lat = parseFloat(element.customer_properties[0].lat);
          this.lng = parseFloat(element.customer_properties[0].lng);
          break;
        }
      }
    }
  }

  subscribeAddCustomer(instance) {
    this._service.addCustomerEmitter.subscribe(function (value) {
      instance.total_customers = value.totalcustomers;
      if (instance.customer_list) {
        instance.customer_list.push(value.customerdetail);
      } else {
        instance.customer_list = [value.customerdetail];
        instance.customer_list1 = instance.customer_list;
        instance.lat = parseFloat(instance.customer_list1[0].customers_addresses[0].lat);
        instance.lng = parseFloat(instance.customer_list1[0].customers_addresses[0].lng);
      }
    });
    this.searchtxt = ""
  }

  getVals(page) {
    this.isLoaderVisible = true
    this._service.get_customer('customerslist?page=' + page).subscribe(
      response => {
        this.curr_page = parseInt(response.data.currentpage);
        this.setDate(response);
        this.isLoaderVisible = false;
      },
      error => {
        this.isLoaderVisible = false;
        if (error.status !== '401') {
          this.alert.showErrorMessage("Please try again.")
        }
      }
    );
  }

  onPrev() {
    if (this.curr_page > 1) {
      this.getVals(this.curr_page - 1);
    }
  }

  onNext() {
    if (this.curr_page < this.totalpage) {
      this.getVals(this.curr_page + 1);
    }
  }

  addCustomer() {
    this.search_flag = false
    this.customer_list1 = this.customer_list
    this.total_customers = this.customerlistlength
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.searchtxt = ""
    this.customer_list1 = this.customer_list
    const modalRef = this.modalService.open(AddCustomerComponent, ngbModalOptions);
  }

  showDetails(id) {
    this.customerid = id;
    this.router.navigate(['customerdetail', this.customerid]);
  }

  setId(e, id) {
    if (e.target.checked) {
      this.selected_ids.push(id);
    } else {
      const index = this.selected_ids.indexOf(id);
      if (index > -1) {
        this.selected_ids.splice(index, 1);
      }
    }
  }

  showDelete() {
    this.delete = true;
  }

  checkSelectedIds() {
    if (this.selected_ids.length > 0)
      this.hasSelectedIds = true
    else {
      this.alert.alert_msg("Please select the list items to be deleted.")
      this.delete = false
    }
  }

  deleteCustomer() {
    if (this.selected_ids.length > 0) {
      this._service.delete_customer('deletecustomer', { customerids: this.selected_ids }).subscribe(
        response => {
          this.delete = false;
          this.page = 1;
          this.getdata();
          this.alert.alert_msg("Selected customer records deleted successfully.")
          this.closeModal();
          this.page = 1;
        },
        error => {
          console.log(error);
        }
      );
    }
  }
  closeModal() {
    this.closeBtn.nativeElement.click();
  }

  checkValue(e) {
    if (e.target.value == "") {
      this.page = 1
      this.getVals(this.page)
      this.search_flag = false
      this.customer_list1 = this.customer_list
      this.total_customers = this.customerlistlength
    }
  }

  searchText(txt) {
    if (txt == "") {
      this.search_flag = false
      this.total_customers = this.customerlistlength
      this.alert.alert_msg("Please enter a keyword to search.")
    }
    else {
      this.search_flag = true
      this._service.searchText('searchcustomer', { searchkeyword: txt }).subscribe(
        response => {
          this.total_customers = response.data.totalcustomers
          if (response.data.length == 0) {
            this.alert.alert_msg("No results found.");
          }
          else {
            this.search_list = response.data.customerlist
            this.setPage(1)
          }
        },
        error => {
          if (error.status !== '401') {
            this.alert.showErrorMessage(error.error.message)
          }
        })
    }
  }


  setPage(page: number) {
    this.customer_list1 = this.search_list
    if (page < 1) {
      return;
    }
    this.curr_page = page
    // get pager object from service
    this.pager = this.pagerService.getPager(this.customer_list1.length, page, this.perpagerecord);
    this.loopStartingIndex = this.pager.startIndex;
    // get current page of items
    this.customer_list1 = this.customer_list1.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.isLoaderVisible = false;
    this.totalRecords = this.pager.totalItems;
  }

  toggleMapVisibility() {
    this.show_map = !this.show_map
  }

  showCustomerDetail(cuid) {
    this.router.navigate(['customerdetail/' + cuid])
  }
  showProperty(c_id, p_id, index) {
    this.router.navigate(['customerdetail/' + c_id + '/' + p_id + '/' + index])
  }

  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }


  clickedMarker1(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  uploadrecords(e) {
    this.myFile = ''
    this.file = e.target.files[0];
    if (this.file != undefined) {
      if ((/\.(csv)$/i).test('' + this.file.name)) {
        this.isLoaderVisible = false;
        this.myFile = e.target.files[0];
        this.uploadToServer();
      }
      else {
        this.isLoaderVisible = false;
        e.target.value = '';
        this.alert.showErrorMessage('Please select a csv file.');
      }
    }
  }

  uploadToServer() {
    this.isLoaderVisible = true
    const formData = new FormData();
    formData.append('import_file', this.myFile);
    this._service.uploasCsv('importcustomer', formData).subscribe(
      response => {
        this.isLoaderVisible = false
        this.page = 1;
        this.customerlistlength = response.data.totalcustomers;
        this.total_customers = this.customerlistlength
        this.curr_page = parseInt(response.data.currentpage);
        this.perpagerecord = parseInt(response.data.perpagerecord)
        this.totalpage = this.transform(response.data.totalcustomers / response.data.perpagerecord);
        for (this.i = 0; this.i < this.totalpage; this.i++) {
          this.array.push(this.i + 1);
        }
        this.setDate(response);
      },
      error => {
        if (error.status !== '401') {
          this.alert.showErrorMessage("Please try again")
        }
        this.isLoaderVisible = false
      }
    )
  }
}
