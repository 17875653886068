import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Services } from '../services/services';
import { Users } from '../models/users';
import { validateEmail, passwordsLength, matchingPasswords } from '../Helpers/validations';
import { Alert } from '../Helpers/alert';


@Component({
  selector: 'app-register',
  templateUrl: '../views/register.component.html'
})
export class RegisterComponent implements OnInit {
  register: FormGroup;
  user: Users;
  formErrors = {};
  checkboxValue: boolean = false;
  response: any
  public error: any
  submitted = false
  isLoaderVisible = false;
  
  constructor(private formBuilder: FormBuilder, private alert_message: Alert,
    private router: Router, private user_service: Services,
  ) { }
  get f() {
    return this.register.controls
  }
  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.register = this.formBuilder.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, validateEmail])],
      pass: ['', Validators.compose([Validators.required, passwordsLength])],
      cpass: ['', Validators.compose([Validators.required])],
    }, {
        validator: matchingPasswords('pass', 'cpass')
      })
  }

  onSubmit(valid) {
    this.submitted = true
    this.isLoaderVisible = true;
    this.user = {
      first_name: this.register.get('fname').value,
      last_name: this.register.get('lname').value,
      email: this.register.get('email').value,
      password: this.register.get('pass').value,
      password_confirmation: this.register.get('cpass').value,
    }
    if (valid) {
      if (this.checkboxValue) {
        this.user_service.signupService('signup', this.user).subscribe(
          response => {
            this.isLoaderVisible = false;
            this.response = response
            this.responseTrigger(this.response)
          },
          error => {
            this.isLoaderVisible = false;
            if (error.status != '401')
              this.errorAction(error)
          })
      }
      else {
        this.isLoaderVisible = false;
        this.alert_message.showErrorMessage("Please accept the terms and conditions.")
      }
    }
    else {
      this.alert_message.alert_msg("Please provide appropriate values")
      this.isLoaderVisible = false;
      this.checkboxValue = false
    }
  }

  responseTrigger(res) {
    localStorage.setItem('username', res.name)
    localStorage.setItem('token', res.access_token)
    localStorage.setItem("subscription_status", res.is_subscription_complete)
    this.user_service.loggedInEmitter.emit(res)
    this.router.navigate(['/myprofile'])
  }

  errorAction(error) {
    this.error = error.error.errors
  }
}
