import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LogInComponent } from './controllers/log-in.component';
import { RegisterComponent } from './controllers/register.component';
import { CustomerComponent } from './controllers/customer.component';
import { SubscriptionComponent } from './controllers/subscription.component';
import { ReportsComponent } from './controllers/reports.component';
import { ContractValueComponent } from './controllers/contract-value.component';
import { MyProfileComponent } from './controllers/my-profile.component';
import { AuthGuard } from './services/auth.guard';
import { AddUserSubscriptionComponent } from './controllers/add-user-subscription.component';
import { CustomerDetailComponent } from './controllers/customer-detail.component';
import { ResetpasswordComponent } from './controllers/resetpassword.component';
import { NotificationComponent } from './controllers/notification.component';
import { PaypalUsageComponent } from './controllers/paypal-usage.component';

export const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "login", component: LogInComponent },
  { path: "register", component: RegisterComponent },
  {
    path: "customer",
    component: CustomerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "subscription",
    component: SubscriptionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "passwordreset",
    component: ResetpasswordComponent
  },
  {
    path: "reports/:id",
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "reports/:year/:month/:date/:days",
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "reports",
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "contract-value",
    component: ContractValueComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "myprofile",
    component: MyProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "notification",
    component: NotificationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "addsubscription",
    component: AddUserSubscriptionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "customerdetail/:id",
    component: CustomerDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "customerdetail/:id/:pid",
    component: CustomerDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "customerdetail/:id/:pid/:index",
    component: CustomerDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"executeagreement",
    component:PaypalUsageComponent,

  },
  {
    path: "**",
    redirectTo:'customer'
    // component: CustomerComponent,
    // canActivate: [AuthGuard],
  }
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  declarations: []
})
export class AppRoutingModule { }
