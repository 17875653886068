import swal from 'sweetalert'
export class Alert {

    showErrorMessage(message) {
        swal("Oops!", message, "error")
    }
    alert_msg(message) {
        swal({
            text: message,
            timer: 2000
        });
    }
    alert_process_msg(message){
        swal({
            text: message
        });
    }

}