import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Services } from '../services/services';
import { passwordsLength, validateExt, validatePhone, matchingPasswords } from '../Helpers/validations';
import { Alert } from '../Helpers/alert';
import { validatePhnNumber } from '../Helpers/validations';

@Component({
  selector: 'app-my-profile',
  templateUrl: '../views/my-profile.component.html'
})
export class MyProfileComponent implements OnInit {

  notification_settings: FormGroup
  profile_submitted = false
  change_password = false
  error: any
  profile_error: any
  change_password_error: any
  incorrect_old_password_error = ''
  _message = "My Profile"
  edit_info: FormGroup
  chnge_pwd: FormGroup
  pwd_detail: any
  user_info: any
  userimage: any
  file: File
  add: string = ''
  ext: boolean = false
  img_src = "../../assets/images/logo_contractor.png"
  isLoaderVisible = false;
  isLoaderVisible1 = false;
  isLoaderVisible2 = false;
  isimgLoaderVisible = false;
  notify_customer = 1
  notify_contractor_before_days = 30
  notify_customer_before_days = 7
  states = []
  looparray = []
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('closeBtnPwd') closeBtnPwd: ElementRef;
  @ViewChild('closeBtnNotify') closeBtnNotify: ElementRef;
  constructor(private alert_message: Alert, private editInfo: Services, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.isLoaderVisible = true
    let loop_index = 1
    this.editInfo.edit_user_info('userprofile').subscribe(
      (response) => {
        this.isLoaderVisible = false;
        this.notify_customer = response.data.notify_customer
        this.notify_contractor_before_days = response.data.notify_contractor_before_days
        this.notify_customer_before_days = response.data.notify_customer_before_days
        this.user_info = response.data;
        if (this.user_info.state != null)
          this.user_info.state = this.user_info.state.toUpperCase()
        this.img_src = this.user_info.profile_image
        if (this.user_info.phone_ext != null)
          this.ext = true
      },
      error => {
        this.isLoaderVisible = false;
        if (error.status != '401')
          this.alert_message.showErrorMessage(error.error.message)
      })
    for (loop_index == 1; loop_index <= 60; loop_index++) {
      this.looparray.push(loop_index)
    }
    this.createForm_EditProfile()
    this.createForm_ChangePwd()
    this.createForm_NotificationSettings()
  }
  get f() {
    return this.edit_info.controls
  }

  validateNumber(num) {
    if (!validatePhnNumber(num)) {
      return true
    }
  }
  /*Change logo starts*/
  uploadImage(e) {

    this.file = <File>e.target.files[0];
    const userimage = new FormData()
    if (this.file != null) {
      userimage.append('userimage', this.file, this.file.name)
      // assuming that this file has any extension
      if ((/\.(jpg|jpeg|png)$/i).test('' + this.file.name)) {
        this.isimgLoaderVisible = true;
        this.editInfo.change_logo('edituserimage', userimage).subscribe(
          (response) => {

            this.editInfo.editprofileimage.emit(response.data.profile_image)
            this.img_src = "" + response.data.profile_image
            localStorage.setItem("profile_image", response.data.profile_image)
            setTimeout(() => {
              this.isimgLoaderVisible = false;
            }, 1000);
          },
          error => {
            this.img_src = "../../assets/images/logo_contractor.png"
            this.isimgLoaderVisible = false;
            if (error.status != '401') {
              this.error = error.error.errors.userimage[0]
              this.alert_message.showErrorMessage(this.error)
            }
          })
      }
      else {
        this.isimgLoaderVisible = false;
        e.target.value = ''
        this.alert_message.showErrorMessage('Wrong file extension! File input is cleared.');
      }
    }
    else {
      this.isimgLoaderVisible = false;
    }
  }

  /*Change logo ends*/

  //Code for edit info pop-up starts

  valueChanged(e) {
    var phn_val: string = e.target.value
    var num = phn_val.charAt(phn_val.length - 1)
    if (this.validateNumber(num))
      e.target.value = phn_val.substring(0, phn_val.length - 1)
    else if (phn_val.length == 3 || phn_val.length == 7)
      e.target.value = phn_val + "-"

  }

  extEntered(e) {
    var extension = e.target.value
    if (extension != "") {
      this.ext = true
      var digit = extension.charAt(extension.length - 1)
      if (this.validateNumber(digit)) {
        e.target.value = extension.substring(0, extension.length - 1)
      }
    }
    else
      this.ext = false
  }
  createForm_EditProfile() {
    this.edit_info = this.formBuilder.group({
      c_name: new FormControl(),
      f_name: new FormControl(),
      l_name: new FormControl(),
      ph_num: new FormControl(),
      ext: new FormControl(),
      address1: new FormControl(),
      address2: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      zip: new FormControl()
    })
  }

  editUserInfo() {
    this.editInfo.load_state_list('stateslist').subscribe(
      response => {
        this.states = response.data

      },
      error => {
        if (error.status != '401')
          this.alert_message.showErrorMessage(error.error.message)
        else {
          setTimeout(() => {
            this.closeModal()
          }, 500);
        }

      })

    this.edit_info = this.formBuilder.group({
      c_name: ['', Validators.required],
      f_name: ['', Validators.required],
      l_name: ['', Validators.required],
      ph_num: ['', Validators.compose([Validators.required, validatePhone])],
      ext: ['', validateExt],
      address1: ['', Validators.required],
      address2: '',
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required]
    },
      { validator: validateExt('ext') }
    )
    this.edit_info.setValue({
      c_name: this.user_info.business_name,
      f_name: this.user_info.first_name,
      l_name: this.user_info.last_name,
      ph_num: this.user_info.phone_number,
      ext: this.user_info.phone_ext,
      address1: this.user_info.address1,
      address2: this.user_info.address2,
      city: this.user_info.city,
      state: this.user_info.state,
      zip: this.user_info.zip_code
    })
  }

  onSubmit(valid) {

    this.profile_submitted = true
    this.isLoaderVisible1 = true
    if (valid) {
      this.user_info.business_name = this.edit_info.controls.c_name.value
      this.user_info.first_name = this.edit_info.controls.f_name.value
      this.user_info.last_name = this.edit_info.controls.l_name.value
      this.user_info.phone_number = this.edit_info.controls.ph_num.value

      if (this.edit_info.controls.ph_num.value.length == 10) {
        let temp = this.user_info.phone_number.substring(0, 3) + '-'
        temp += this.user_info.phone_number.substring(3, 6) + '-'
        temp += this.user_info.phone_number.substring(6)
        this.user_info.phone_number = temp;
      } else if (this.edit_info.controls.ph_num.value.length == 11) {
        let index = this.user_info.phone_number.lastIndexOf('-');
        if (index == 3) {
          let temp = this.user_info.phone_number.substring(0, 7) + '-'
          temp += this.user_info.phone_number.substring(7)
          this.user_info.phone_number = temp;
        } else if (index == 6) {
          let temp = this.user_info.phone_number.substring(0, 3) + '-'
          temp += this.user_info.phone_number.substring(3)
          this.user_info.phone_number = temp;
        }
      }

      this.user_info.phone_ext = this.edit_info.controls.ext.value
      this.user_info.address1 = this.edit_info.controls.address1.value
      this.user_info.address2 = this.edit_info.controls.address2.value
      this.user_info.city = this.edit_info.controls.city.value
      this.user_info.state = this.edit_info.controls.state.value
      this.user_info.zip_code = this.edit_info.controls.zip.value
      this.editInfo.update_user_info('editprofile', this.user_info).subscribe(
        (response) => {

          this.isLoaderVisible1 = false;
          this.editInfo.editprofile.emit(response.data.userdetail)
          this.alert_message.alert_msg('Values updated successfully!');
          this.closeModal()
        },
        error => {
          this.isLoaderVisible1 = false;
          if (error.status != '401')
            this.profile_error = error.error.errors
          else {
            this.closeModal()
          }
        })
    }
    else {
      this.isLoaderVisible1 = false;
      this.alert_message.showErrorMessage("Please provide appropriate values!")
    }
  }
  //Code to edit info pop-up ends

  //Code to change password pop-up starts
  createForm_ChangePwd() {
    this.chnge_pwd = this.formBuilder.group({
      o_pwd: new FormControl(),
      n_pwd: new FormControl(),
      c_pwd: new FormControl(),

    })
  }
  createForm_NotificationSettings() {
    this.notification_settings = this.formBuilder.group({
      notify_contractor_before_days: [30],
      notify_customer: ['1'],
      notify_customer_before_days: [7]
    })
  }
  edit_password() {
    this.chnge_pwd = this.formBuilder.group({
      o_pwd: ['', Validators.required],
      n_pwd: ['', Validators.compose([Validators.required, passwordsLength])],
      c_pwd: ['', Validators.compose([Validators.required])],
    },
      {
        validator: matchingPasswords('n_pwd', 'c_pwd')
      })
  }

  onClick(valid) {
    this.change_password = true
    this.isLoaderVisible2 = true;
    if (valid) {
      this.pwd_detail = {
        oldpassword: this.chnge_pwd.controls.o_pwd.value,
        password: this.chnge_pwd.controls.n_pwd.value,
        password_confirmation: this.chnge_pwd.controls.c_pwd.value
      }
      this.editInfo.change_password('editpassword', this.pwd_detail).subscribe(
        (response) => {
          this.isLoaderVisible2 = false;
          this.alert_message.alert_msg('Password updated successfully!');
          this.closeModal()

        },
        error => {

          this.isLoaderVisible2 = false;
          if (error.status != '401') {
            if (error.error.message = "Old password is not correct.") {
              this.incorrect_old_password_error = error.error.message
              this.chnge_pwd.controls.o_pwd.setValue('')
            }
            this.change_password_error = error.error.errors
          }
          else
            this.closeModal()
        })
    }
    else {
      this.isLoaderVisible2 = false;
      this.alert_message.showErrorMessage("Please provide appropriate values!")
    }
  }
  //Code to change password pop-up ends
 
  //method to close modal
  closeModal(): void {
    this.closeBtn.nativeElement.click();
    this.closeBtnPwd.nativeElement.click();
    // this.closeBtnNotify.nativeElement.click();
  }
}

