import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: '../views/elements/footer.component.html'

})
export class FooterComponent implements OnInit {
  isLoggedin: boolean
  constructor() { }
  ngOnInit() {
    var access_token = localStorage.getItem("token");
    if (access_token == null) {
      this.isLoggedin = false;
    } else {
      this.isLoggedin = true;
    }
  }

}
