import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Services } from '../services/services';
import { addMonths, subYears, getYear, addDays, getMonth } from 'date-fns';
import { Alert } from '../Helpers/alert';
import { Router, ActivatedRoute } from '@angular/router';
import { last } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-reports',
  templateUrl: '../views/reports.component.html'
})
export class ReportsComponent implements OnInit, OnDestroy {
  isLoaderVisible = false;
  pageSize = 20
  total_records = 0
  page = 1
  view: any
  previous: any
  report: FormGroup;
  report_type_filter = ''
  search_complete = false
  report_filters: any
  _message = "Reports"
  values: any
  submitted = false
  service_due_date_from = new Date(Date.now())
  service_due_date_to = new Date(addMonths(Date.now(), 1))
  propertylat: any;
  propertylng: any;
  show_map = false
  lat = 0
  lng = 0

  subscriber: any;
  constructor(private router: Router, private formBuilder: FormBuilder, private _service: Services, private _alert: Alert, private route: ActivatedRoute) {


  }
  ngOnInit() {
    this.createForm()
    this.onReset()
    this.subscribeNotifyFlag(this);

    /*code for getting routed from any other page for notification */
    let param = this.route.snapshot.params.id;
    if (param == 0) {
      this.forNotificationsTillNow()
      this.onSearch()
    }
    else if (param == 1) {
      this.forNotificationsNextMonth()
      this.onSearch()
  }
    // var year_next=0
    // var month_next=0
    // month_next= getMonth(new Date(addMonths(Date.now(),1)))
    // year_next= getYear(new Date(addMonths(Date.now(),1)))
    // console.log(month_next,year_next)
    // console.log(new Date(year_next,month_next,0))

    // this.service_due_date_to=new Date(new Date(year_next,month_next+1,0))

    /*code for getting routed from any other page for notification ends */
    let year= this.route.snapshot.params.year;
    
    let month= this.route.snapshot.params.month;
    let date= this.route.snapshot.params.date;
    let days=this.route.snapshot.params.days;
    var dt=new Date(year,month-1,date)
    // console.log(dt)
    if(year !== undefined){
      console.log('hi');
      this.redirectFromMail(dt,days)
    }

  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  subscribeNotifyFlag(instance) {
    instance.subscriber = instance._service.notification_flag.subscribe(function (value) {
      if (value == 0) {
        instance.forNotificationsTillNow()
      }
      if (value == 1) {
        instance.forNotificationsNextMonth()
      }
      instance.onSearch()
    });
  }
  forNotificationsTillNow() {
    var date = new Date()
    var year = date.getFullYear() - 1
    var day = date.getDate()
    var month = date.getMonth()
    this.service_due_date_from = new Date(year, month, day)
    this.service_due_date_to = new Date(Date.now())
    this.report.patchValue(
      {
        servicetype: '2',
        service_due_status: '2',
        zip_code: '',
        city: '',
        recordperpage: '20',
        view: '1'
      })
  }
  forNotificationsNextMonth() {
    this.service_due_date_from = new Date(addDays(Date.now(), 1))
    this.service_due_date_to = new Date(addMonths(Date.now(), 1))
    this.report.patchValue(
      {
        servicetype: '2',
        service_due_status: '4',
        zip_code: '',
        city: '',
        recordperpage: '20',
        view: '1'
      })
  }
  redirectFromMail(date,days){
    this.service_due_date_from = date
    this.service_due_date_to = new Date(addDays(date, days))
    this.report.patchValue(
      {
        servicetype: '2',
        service_due_status: '4',
        zip_code: '',
        city: '',
        recordperpage: '20',
        view: '1'
      })
      this.onSearch()
  }
  createForm() {
    this.report = this.formBuilder.group({
      servicetype: '1',
      service_due_status: '4',
      service_due_date_from: this.service_due_date_from,
      service_due_date_to: this.service_due_date_to,
      zip_code: '',
      city: '',
      recordperpage: '20',
      view: '1'
    })
  }
  get f() {
    return this.report.controls
  }
  onReset() {
    this.isLoaderVisible = true;
    this.values = []
    this.search_complete = false
    this.page = 1
    this.report.setValue(
      {

        servicetype: '1',
        service_due_status: '4',
        service_due_date_from: new Date(Date.now()),
        service_due_date_to: new Date(addMonths(Date.now(), 1)),
        zip_code: '',
        city: '',
        recordperpage: '20',
        view: '1'
      }
    )
    this.isLoaderVisible = false;
    // console.log(this.page);
  }
  onChange() {
    this.values = []
    this.search_complete = false
    this.page = 1
  }

  onDownload() {
    this.isLoaderVisible = true;
    this._service.download_report('downloadreport', this.report_filters).subscribe(
      response => {
        this.isLoaderVisible = false;
        this.download(response)


      }, error => {
        this.isLoaderVisible = false;
        this._alert.alert_msg("Something went wrong, please try again!")
      })

  }
  download(data) {
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([data], { type: 'csv' });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'HVAC-Report.csv';/* your file name*/
    a.click();
    return 'success';
  }
  onSearch() {
    this.submitted = true
    this.isLoaderVisible = true;
    var service_due_date_from = (this.service_due_date_from.getMonth() + 1) + "/" + this.service_due_date_from.getDate() + "/" + this.service_due_date_from.getFullYear();
    var service_due_date_to = (this.service_due_date_to.getMonth() + 1) + "/" + this.service_due_date_to.getDate() + "/" + this.service_due_date_to.getFullYear();
    if (this.report.get("servicetype").value == '1') {
      this.report_type_filter = "customer"
    }
    else {
      this.report_type_filter = "property"
    }
    this.report_filters = {
      servicetype: this.report.get("servicetype").value,
      service_due_status: this.report.get("service_due_status").value,
      service_due_date_from: service_due_date_from,
      service_due_date_to: service_due_date_to,
      zip_code: this.report.get("zip_code").value,
      city: this.report.get("city").value,
      recordperpage: this.report.get("recordperpage").value,
      pageno: this.page
    }

    this.pageSize = this.report_filters.recordperpage
    this._service.show_report('report', this.report_filters).subscribe(
      response => {
        this.isLoaderVisible = false;
        if (response!=null && response.data.totalrecord > 0) {
          this.total_records = response.data.totalrecord
          this.previous = false;
          this.lat = parseFloat(response.data.datalist[0].address_lat)
          this.lng = parseFloat(response.data.datalist[0].address_lng)
          this.search_complete = true
          this.values = response.data.datalist
        }
        else {
          this.isLoaderVisible = false;
          this.search_complete = false
          this._alert.alert_msg("No such data available. Please try resetting filters.")
        }

      },
      error => {
        this.isLoaderVisible = false;
        this.search_complete = false
        this._alert.showErrorMessage("Something went wrong. Please try again.")
      }
    )

  }
  onChangeview(e) {
    let val = e.target.value
    if (val == '2') {

      this.report.patchValue(
        { view: '2' })
    }
    else {

      this.report.patchValue(
        { view: '1' })
    }
    this.search_complete = true
  }
  showCustomer(cuid) {
    this.router.navigate(['customerdetail/' + cuid])
  }
  showProperty(c_id, p_id) {
    this.router.navigate(['customerdetail/' + c_id + '/' + p_id])
  }

  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  getVals(page) {
    this.page = page
    this.onSearch()
  }

}
