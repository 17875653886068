import { Component, OnInit } from '@angular/core';
import { Services } from '../services/services';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Alert } from '../Helpers/alert';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as frLocale from 'date-fns/locale/fr';
import { DatepickerOptions } from 'ng2-datepicker';
import { addYears } from 'date-fns';
import { isvalidDate } from '../Helpers/validations';
@Component({
  selector: 'app-add-equipment',
  templateUrl: '../views/add-equipment.component.html'
})
export class AddEquipmentComponent implements OnInit {
  duplicate = false
  submitted = false
  propertyid
  customerid
  equipment_detail
  equipment: FormGroup
  file: File
  // service_start_date = new Date();
  // service_end_date = addYears(Date.now(), 1)
  // start_year
  // end_year
  // years = new Array()
  filter_size: FormArray
  equipmentimages: Array<File> = [];
  myFiles: string[] = [];
  filters: { filter_size }
  imagesname = '';
  isLoaderVisible = false;
  f_size_value = false;
  filter_sizes = [
    '8 x 16 x 4',
    '8 x 30 x 4',
    '10 x 10 x 4',
    '10 x 15 x 4',
    '10 x 16 x 4',
    '10 x 20 x 4',
    '10 x 24 x 4',
    '10 x 25 x 4',
    '12 x 12 x 4',
    '12 x 16 x 4',
    '12 x 18 x 4',
    '12 x 20 x 4',
    '12 x 24 x 4',
    '12 x 25 x 4',
    '12 x 27 x 4',
    '12 x 30 x 4',
    '12 x 36 x 4',
    '14 x 14 x 4',
    '14 x 18 x 4',
    '14 x 20 x 4',
    '14 x 24 x 4',
    '14 x 25 x 4',
    '14 x 30 x 4',
    '14 x 36 x 4',
    '15 x 20 x 4',
    '15 x 25 x 4',
    '15 x 30 x 4',
    '16 x 16 x 4',
    '16 x 20 x 4',
    '16 x 24 x 4',
    '16 x 25 x 4',
    '16 x 30 x 4',
    '16 x 36 x 4',
    '17 x 20 x 4',
    '17 x 22 x 4',
    '17 x 25 x 4',
    '18 x 18 x 4',
    '18 x 20 x 4',
    '18 x 22 x 4',
    '18 x 24 x 4',
    '18 x 25 x 4',
    '18 x 30 x 4',
    '18 x 36 x 4',
    '19 x 21 x 4',
    '20 x 20 x 4',
    '20 x 22 x 4',
    '20 x 23 x 4',
    '20 x 24 x 4',
    '20 x 25 x 4',
    '20 x 30 x 4',
    '20 x 36 x 4',
    '21 x 22 x 4',
    '22 x 22 x 4',
    '22 x 24 x 4',
    '24 x 24 x 4',
    '24 x 30 x 4',
    '24 x 36 x 4',
    '25 x 25 x 4',
    '25 x 28 x 4',
    '25 x 29 x 4',
    '25 x 32 x 4',
    '27 x 27 x 4',
    '28 x 30 x 4',
    '29 x 29 x 4',
    '8 x 16 x 1',
    '8 x 30 x 1',
    '10 x 10 x 1',
    '10 x 15 x 1',
    '10 x 16 x 1',
    '10 x 20 x 1',
    '10 x 24 x 1',
    '10 x 25 x 1',
    '12 x 12 x 1',
    '12 x 16 x 1',
    '12 x 18 x 1',
    '12 x 20 x 1',
    '12 x 24 x 1',
    '12 x 25 x 1',
    '12 x 27 x 1',
    '12 x 30 x 1',
    '12 x 36 x 1',
    '14 x 14 x 1',
    '14 x 18 x 1',
    '14 x 20 x 1',
    '14 x 24 x 1',
    '14 x 25 x 1',
    '14 x 30 x 1',
    '14 x 36 x 1',
    '15 x 20 x 1',
    '15 x 25 x 1',
    '15 x 30 x 1',
    '16 x 16 x 1',
    '16 x 20 x 1',
    '16 x 24 x 1',
    '16 x 25 x 1',
    '16 x 30 x 1',
    '16 x 36 x 1',
    '17 x 20 x 1',
    '17 x 22 x 1',
    '17 x 25 x 1',
    '18 x 18 x 1',
    '18 x 20 x 1',
    '18 x 22 x 1',
    '18 x 24 x 1',
    '18 x 25 x 1',
    '18 x 30 x 1',
    '18 x 36 x 1',
    '19 x 21 x 1',
    '20 x 20 x 1',
    '20 x 22 x 1',
    '20 x 23 x 1',
    '20 x 24 x 1',
    '20 x 25 x 1',
    '20 x 30 x 1',
    '20 x 36 x 1',
    '21 x 22 x 1',
    '22 x 22 x 1',
    '22 x 24 x 1',
    '24 x 24 x 1',
    '24 x 30 x 1',
    '24 x 36 x 1',
    '25 x 25 x 1',
    '25 x 28 x 1',
    '25 x 29 x 1',
    '25 x 32 x 1',
    '27 x 27 x 1',
    '28 x 30 x 1',
    '29 x 29 x 1',
    '30 x 30 x 1',
    '8 x 16 x 2',
    '8 x 30 x 2',
    '10 x 10 x 2',
    '10 x 15 x 2',
    '10 x 16 x 2',
    '10 x 20 x 2',
    '10 x 24 x 2',
    '10 x 25 x 2',
    '12 x 12 x 2',
    '12 x 16 x 2',
    '12 x 18 x 2',
    '12 x 20 x 2',
    '12 x 24 x 2',
    '12 x 25 x 2',
    '12 x 27 x 2',
    '12 x 30 x 2',
    '12 x 36 x 2',
    '14 x 14 x 2',
    '14 x 18 x 2',
    '14 x 20 x 2',
    '14 x 24 x 2',
    '14 x 25 x 2',
    '14 x 30 x 2',
    '14 x 36 x 2',
    '15 x 20 x 2',
    '15 x 25 x 2',
    '15 x 30 x 2',
    '16 x 16 x 2',
    '16 x 20 x 2',
    '16 x 24 x 2',
    '16 x 25 x 2',
    '16 x 30 x 2',
    '16 x 36 x 2',
    '17 x 20 x 2',
    '17 x 22 x 2',
    '17 x 25 x 2',
    '18 x 18 x 2',
    '18 x 20 x 2',
    '18 x 22 x 2',
    '18 x 24 x 2',
    '18 x 25 x 2',
    '18 x 30 x 2',
    '18 x 36 x 2',
    '19 x 21 x 2',
    '20 x 20 x 2',
    '20 x 22 x 2',
    '20 x 23 x 2',
    '20 x 24 x 2',
    '20 x 25 x 2',
    '20 x 30 x 2',
    '20 x 36 x 2',
    '21 x 22 x 2',
    '22 x 22 x 2',
    '22 x 24 x 2',
    '24 x 24 x 2',
    '24 x 30 x 2',
    '24 x 36 x 2',
    '25 x 25 x 2',
    '25 x 28 x 2',
    '25 x 29 x 2',
    '25 x 32 x 2',
    '27 x 27 x 2',
    '28 x 30 x 2',
    '29 x 29 x 2',
    '30 x 30 x 2'
  ]
  error: any;
  options: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,

    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: frLocale,
    minDate: new Date(Date.now()), // Minimal selectable date
    maxDate: new Date(Date.now()),  // Maximal selectable date
    barTitleIfEmpty: 'Click to select a date',
    placeholder: 'Click to select a date', // HTML input placeholder attribute (default: '') 
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };
  //error: { equipment, filter_size, model_number, serial_number, service_start_date, equipmentimages,service_end_date }
  constructor(private alert: Alert, private service: Services, private formBuilder: FormBuilder, private active_modal: NgbActiveModal) { }

  ngOnInit() {
    // this.days = []
    // let i = 0
    // for (i = 1; i <= 31; i++) {
    //   this.days.push(i);
    //   if (i != 31) {
    //     this.no_of_inspection.push(i)
    //   }
    // }
    this.createForm();
  }
  get f() { return this.equipment.controls; }
  createDuplicate() {
  }
  createForm() {
    this.equipment = this.formBuilder.group({
      aircondition_area: ['', Validators.required],
      aircondition_area_other: '',
      equipment: ['', Validators.required],
      location_of_equipment: ['', Validators.required],
      filter_size: this.formBuilder.array([this.createFilter()]),
      belt_size: [''],
      manufacturer_name: [''],
      model_number: ['', Validators.required],
      serial_number: ['', Validators.required],
      equipmentimages: [''],
      additional_notes: [''],
      filter_size_custom: ['']

    });
  }
  createFilter() {
    return this.formBuilder.group({
      filter_size:['', Validators.required]
    });

  }
  addFilter(): void {
    this.filter_size = this.equipment.get('filter_size') as FormArray;
    this.filter_size.push(this.createFilter());
  }
  removeUnit(i: number) {
    const control = <FormArray>this.equipment.controls['filter_size'];
    control.removeAt(i);
  }
  onSubmit() {
    this.submitted = true;
    this.isLoaderVisible = true
    if (this.equipment.valid) {
      this.addEquipment();
    }
    if (this.equipment.invalid) {
      this.isLoaderVisible = false;
      this.alert.showErrorMessage('Please provide appropriate values.');
      return false;
    }
  }

  getFileDetails(e) {
    this.myFiles = [];
    for (var i = 0; i < e.target.files.length; i++) {
      this.file = e.target.files[i];
      if ((/\.(jpg|jpeg|png)$/i).test('' + this.file.name)) {
        this.isLoaderVisible = false;
        this.myFiles.push(e.target.files[i]);
        this.imagesname += this.file.name + '<BR />';
      }
      else {
        this.isLoaderVisible = false;
        e.target.value = '';
        this.alert.showErrorMessage('Wrong file extension! File input is cleared.');
      }
    }
  }
  addEquipment() {
    this.propertyid = localStorage.getItem('p_id');
    this.customerid = localStorage.getItem('c_id');
    const formData = new FormData();
    for (let i = 0; i < this.myFiles.length; i++) {
      formData.append('equipmentimages[]', this.myFiles[i]);
    }

    if (this.equipment.valid) {
      // let index = 0;
      // (this.equipment.get('filter_size').value).forEach(element => {
      //   if (element.filter_size == "" && index > 0) {
      //     (this.equipment.get('filter_size').value).splice(index, 1)
      //   }
      //   index = index + 1;
      // });
      formData.append('aircondition_area', this.equipment.get('aircondition_area').value);
      formData.append('aircondition_area_other', this.equipment.get('aircondition_area_other').value)
      formData.append('equipment', this.equipment.get('equipment').value);
      formData.append('location_of_equipment', this.equipment.get('location_of_equipment').value);
      formData.append('belt_size', this.equipment.get('belt_size').value);
      formData.append('manufacturer_name', this.equipment.get('manufacturer_name').value);
      formData.append('model_number', this.equipment.get('model_number').value);
      formData.append('serial_number', this.equipment.get('serial_number').value);
      formData.append('additional_notes', this.equipment.get('additional_notes').value);
      formData.append('propertyid', this.propertyid);
      formData.append('customerid', this.customerid);
      if(this.f_size_value == false){
        formData.append('filter_size', JSON.stringify(this.equipment.get('filter_size').value));
      }
      else{
       var value= '[{"filter_size":"'+this.equipment.get('filter_size_custom').value+'"}]';
        formData.append('filter_size', value);
      }
      
      this.service.add_equipment('addequipment', formData).subscribe(
        response => {
          this.isLoaderVisible = false
          this.service.notification.emit(response.data.notificationarray)
          this.service.addEquipmentEmitter.emit(response.data);
          this.alert.alert_msg("Equipment detail added successfully!")
          localStorage.removeItem('p_id')
          localStorage.removeItem('c_id')
          this.closeModal()
        },
        error => {
          this.isLoaderVisible = false
          this.error = error.error.errors;
        });
    }
    else {
      this.isLoaderVisible = false
    }
  }

  changeFilterValue(e) {
    if (e.target.value == "Custom")
      this.f_size_value = true
  }
  closeModal() {
    this.active_modal.close()
  }

  // inspectionDetails(e) {
  //   this.years = []
  //   var i = 0;

  //   var increement = false;
  //   var decreement = false;
  //   var startIndex = 0;
  //   var removeItems = 0;
  //   var newInspectionCount = e.target.value;
  //   let items = this.equipment.get('inspectiondetail') as FormArray;
  //   //console.log("old=" + items.length + " new=" + newInspectionCount)
  //   if (items.length > 0 && newInspectionCount > items.length) {
  //     increement = true;
  //     startIndex = items.length;
  //     //  console.log('--startIndex ' + startIndex)
  //   } else if (items.length > 0 && newInspectionCount < items.length) {
  //     decreement = true;
  //   } else {
  //     increement = true;
  //   }
  //   // console.log("increement=" + increement + " decreement=" + decreement + " startIndex=" + startIndex)
  //   if (increement) {
  //     let item;
  //     for (i = startIndex; i < newInspectionCount; i++) {
  //       this.inspection_detail_count.push(i)
  //       item = this.formBuilder.group({
  //         service_day: '',
  //         service_month: ['', Validators.required],
  //         service_year: ['', Validators.required]
  //       });
  //       items.push(item);
  //     }
  //   } else if (decreement) {
  //     let len = items.length;
  //     while (items.length > newInspectionCount) {
  //       items.removeAt(--len)
  //       this.inspection_detail_count.pop();
  //     }
  //   }
  //   // console.log('items.length ' + items.length);
  //   /*let items = this.equipment.get('inspectiondetail') as FormArray;
  //   items = this.formBuilder.array([this.createInspectionDetail()])
  //   this.inspection_detail_count.push(0)
  //   //items =
  //   for(i=1;i<e.target.value;i++){
  //     this.inspection_detail_count.push(i)
  //     //this.equipment.controls.inspectiondetail[i]=this.createInspectionDetail()
  //     items.push(this.createInspectionDetail());
  //   }*/
  //   // console.log('this.service_start_date ' + this.service_start_date);
  //   this.start_year = this.service_start_date.getFullYear()
  //   this.end_year = this.service_end_date.getFullYear()
  //   while (this.start_year <= this.end_year) {
  //     this.years.push(this.start_year++)
  //   }
  // }

}
