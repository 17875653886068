﻿
export class PagerService {

    getPager(totalItems: number, currentPage, pageSize) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);
        let startPage: number, endPage: number;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }
        // calculate start and end item indexes
        let startIndex: number = (currentPage - 1) * pageSize;
        let num: number = parseInt(startIndex + "") + parseInt(pageSize + "") - parseInt(1 + "")
        let endIndex = Math.min(num, (totalItems - 1));
        //alert('Ist param='+(startIndex + pageSize - 1)+' 2nd param='+(totalItems - 1)+' result '+endIndex+' startIndex='+startIndex+' pageSize='+pageSize+'num '+num);
        let pages: number[] = [];
        var j = 0;
        // create an array of pages to ng-repeat in the pager control
        for (var i = startPage; i < (endPage + 1); i++) {
            pages[j] = i;
            j++;
        }
        //let pages = _.range(startPage, endPage + 1);
        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }
}
