import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Property } from '../models/property';
import { Customer } from '../models/customer';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { Services } from '../services/services';
import { Alert } from '../Helpers/alert';
import { addYears } from 'date-fns';
import { isvalidDate } from '../Helpers/validations';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-add-property',
  templateUrl: '../views/add-property.component.html'
})

export class AddPropertyComponent implements OnInit {
  isLoaderVisible = false
  marked = true
  property: FormGroup
  property_details: Property
  customerInfo: Customer;
  submitted = false
  customerid: string
  contract_date = new Date();
  contract_end_date = addYears(Date.now(),1);
  file: File
  contract_files: File[] = []
  images: File[] = []
  imagesname = '';
  contractfiles = ''
  filename = '';
  error: any;
  // service_start_date = new Date();
  // service_end_date = addYears(Date.now(), 1);
  start_year;
  end_year;
  years = new Array();
  inspection_detail_count = new Array();
  days = [];
  no_of_inspection = [];
  constructor(private alert: Alert, private _service: Services, private formBuilder: FormBuilder, private active_modal: NgbActiveModal, private _decimalPipe: DecimalPipe) { }

  ngOnInit() {
    let customerInfo = localStorage.getItem('customer_info');
    if(customerInfo !== null) {
      this.customerInfo = JSON.parse(customerInfo);
    }
    this.days = []
    let i = 0
    for (i = 1; i <= 31; i++) {
      this.days.push(i);
      if (i != 31) {
        this.no_of_inspection.push(i)
      }
    }
    this.createForm();
    this.loadCustomerBillingAddress();

  }

  get f() { return this.property.controls; }

  formatText(event: any) {
    if(event.target.value == "") {
      return;
    }
    let txt = event.target.value;
    if(event.type === 'focus') {
      event.target.value = this.clearNumberFormat(txt);
    } else if(event.type === 'blur') {
      event.target.value = '$' + this._decimalPipe.transform((Number(txt)).toFixed(2),"1.2-2");
    }
  }

  clearNumberFormat(number: string) {
    return number.replace(/\$|,/g, '')
  }

  createForm() {
    this.property = this.formBuilder.group({
      building_name: ['', Validators.required],
      property_address1: ['', Validators.required],
      property_address2: [''],
      property_zip: ['', Validators.required],
      property_state: ['', Validators.required],
      property_city: ['', Validators.required],
      contract_type: [''],
      contract_amount: [''],
      contract_date: ['', Validators.required],
      contract_end_date: ['', Validators.required],
      contract_term: ['', Validators.required],
      contract_term_type: ['', Validators.required],
      contract_billing: ['', Validators.required],
      contract_billing_custom: [''],
      contract: [''],
      property_image: [''],
      no_of_inspections: ['', Validators.required],
      // service_start_date: ['', Validators.required],
      // service_end_date: ['', Validators.required],
      inspectiondetail: this.formBuilder.array([])
    })
  }

  toggleVisibility(e) {
    this.marked = e.target.checked;
    if (!this.marked) {
      this.property.controls.property_address1.setValue('');
      this.property.controls.property_address2.setValue('');
      this.property.controls.property_city.setValue('');
      this.property.controls.property_state.setValue('');
      this.property.controls.property_zip.setValue('');
    } else {
      this.loadCustomerBillingAddress();
    }
  }

  loadCustomerBillingAddress() {
    this.property.controls.property_address1.setValue(this.customerInfo.billing_address1);
    this.property.controls.property_address2.setValue(this.customerInfo.billing_address2);
    this.property.controls.property_city.setValue(this.customerInfo.billing_city);
    this.property.controls.property_state.setValue(this.customerInfo.billing_state);
    this.property.controls.property_zip.setValue(this.customerInfo.billing_zip);
  }

  onSubmit() {
    this.submitted = true
    this.isLoaderVisible = true
    const controls = this.property.controls;
    // console.log(controls)
    //     for (const name in controls) {
    //       if (controls[name].invalid) {
    //         console.log("Invalid name "+ name);
    //       }
    //     }



    let valid_dates = true
    let month = ''
    let inspectiondetailvalues = this.property.get('inspectiondetail').value
    inspectiondetailvalues.forEach(element => {
      switch (element.service_month) {
        case "1":
          month = "January"
          break;
        case "2":
          month = "February"
          break;
        case "3":
          month = "March"
          break;
        case "4":
          month = "April"
          break;
        case "5":
          month = "May"
          break;
        case "6":
          month = "June"
          break;
        case "7":
          month = "July"
          break;
        case "8":
          month = "August"
          break;
        case "9":
          month = "September"
          break;
        case "10":
          month = "Octobe"
          break;
        case "11":
          month = "November"
          break;
        case "12":
          month = "December"
          break;
        default:
          month = element.service_month
          break;
      }


      if (!isvalidDate(element.service_day, element.service_month, element.service_year)) {
        if (element.service_day != "") {
          this.alert.showErrorMessage(month + "-" + element.service_year + " cannot have " + element.service_day + " days.");
          valid_dates = false
        }
      }
    });
    if (valid_dates && this.property.valid) {
      this.customerid = localStorage.getItem('customerid')

      const formData = new FormData();
      for (var i = 0; i < this.contract_files.length; i++) {
        formData.append('contract_file[]', this.contract_files[i])
      }
      for (var i = 0; i < this.images.length; i++) {
        formData.append('propertyimages[]', this.images[i])
      }
      var formatdate = (this.contract_date.getMonth() + 1) + "/" + this.contract_date.getDate() + "/" + this.contract_date.getFullYear();
      var formatdate1 = (this.contract_end_date.getMonth() + 1) + "/" + this.contract_end_date.getDate() + "/" + this.contract_end_date.getFullYear();
      // var formatstartdate = (this.service_start_date.getMonth() + 1) + "/" + this.service_start_date.getDate() + "/" + this.service_start_date.getFullYear();
      // var formenddate = (this.service_end_date.getMonth() + 1) + "/" + this.service_end_date.getDate() + "/" + this.service_end_date.getFullYear();
      //'aircondition_area', this.property.get('aircondition_area').value);
      formData.append('building_name', this.property.get("building_name").value),
        formData.append('address1', this.property.get("property_address1").value),
        formData.append('address2', this.property.get("property_address2").value),
        formData.append('city', this.property.get("property_city").value),
        formData.append('state', this.property.get("property_state").value),
        formData.append('zip_code', this.property.get("property_zip").value),
        formData.append('contract_type', this.property.get("contract_type").value),
        formData.append('contract_amount', this.clearNumberFormat(this.property.get("contract_amount").value)),
        formData.append('contract_term', this.property.get("contract_term").value),
        formData.append('contract_date', formatdate),
        formData.append('contract_end_date', formatdate1),        
        formData.append('contract_term_type', this.property.get("contract_term_type").value),
        formData.append('contract_billing', this.property.get("contract_billing").value),
        formData.append('contract_billing_custom', this.property.get("contract_billing_custom").value),
        formData.append('customerid', this.customerid)
        formData.append('no_of_inspections', this.property.get('no_of_inspections').value);
      // formData.append('service_start_date', formatstartdate);
      // formData.append('service_end_date', formenddate);
      formData.append('inspectiondetail', JSON.stringify(this.property.get('inspectiondetail').value));
      this._service.add_property('addproperty', formData).subscribe(
        response => {

          this.isLoaderVisible = false
        
          this._service.addPropertyEmitter.emit(response.data.propertydetail);
          this.closeModal()
          this.alert.alert_msg("Property details added successfully!")
        }, error => {
          // console.log(error)
          if(error.error.message=="Property limit exceeded. Please upgrade your plan.")
          {
              this.alert.showErrorMessage("Property limit exceeded. Please upgrade your plan.")
          }
          this.isLoaderVisible = false
          this.error = error.error.errors
        });
    }
    else {
      this.isLoaderVisible = false
      // console.log("Invalid!")
    }
  }

  closeModal() {
    this.active_modal.close()
  }
  validateFile1() {

  }
  validateFile(e, value) {

    const regExp1 = new RegExp(/\.(jpg|jpeg|png)$/i)
    const regExp2 = new RegExp(/\.(jpg|jpeg|png|tif|pdf|exl|doc)$/i)

    if (value == 'contract_files') {
      for (var i = 0; i < e.target.files.length; i++) {
        this.file = e.target.files[i];
        if (regExp2.test('' + this.file.name)) {
          this.contract_files.push(e.target.files[i]);
          this.contractfiles += this.file.name + '<BR />';

        }
        else {
          e.target.value = '';
          this.alert.showErrorMessage('Wrong file extension! File input is cleared.');
        }
      }
    }
    else if (value == 'property_image') {

      for (var i = 0; i < e.target.files.length; i++) {
        this.file = e.target.files[i];
        if (regExp1.test('' + this.file.name)) {
          this.images.push(e.target.files[i]);
          this.imagesname += this.file.name + '<BR />';
        }
        else {
          this.isLoaderVisible = false;
          e.target.value = '';
          this.alert.showErrorMessage('Wrong file extension! File input is cleared.');
        }
      }
    }
  }
  inspectionDetails(e) {
    this.years = []
    var i = 0;

    var increement = false;
    var decreement = false;
    var startIndex = 0;
    var removeItems = 0;
    var newInspectionCount = e.target.value;
    let items = this.property.get('inspectiondetail') as FormArray;
    //console.log("old=" + items.length + " new=" + newInspectionCount)
    if (items.length > 0 && newInspectionCount > items.length) {
      increement = true;
      startIndex = items.length;
      //  console.log('--startIndex ' + startIndex)
    } else if (items.length > 0 && newInspectionCount < items.length) {
      decreement = true;
    } else {
      increement = true;
    }
    // console.log("increement=" + increement + " decreement=" + decreement + " startIndex=" + startIndex)
    if (increement) {
      let item;
      for (i = startIndex; i < newInspectionCount; i++) {
        this.inspection_detail_count.push(i)
        item = this.formBuilder.group({
          service_day: '',
          service_month: ['', Validators.required],
          service_year: ['', Validators.required],
          inspection_type: [''],
          inspection_type_custom: [''],
          labor_allotted: ['']
        });
        items.push(item);
      }
    } else if (decreement) {
      let len = items.length;
      while (items.length > newInspectionCount) {
        items.removeAt(--len)
        this.inspection_detail_count.pop();
      }
    }
    // console.log('items.length ' + items.length);
    /*let items = this.equipment.get('inspectiondetail') as FormArray;
    items = this.formBuilder.array([this.createInspectionDetail()])
    this.inspection_detail_count.push(0)
    //items =
    for(i=1;i<e.target.value;i++){
      this.inspection_detail_count.push(i)
      //this.equipment.controls.inspectiondetail[i]=this.createInspectionDetail()
      items.push(this.createInspectionDetail());
    }*/
    // console.log('this.service_start_date ' + this.service_start_date);
    this.start_year = this.contract_date.getFullYear()
    this.end_year = this.contract_end_date.getFullYear()
    while (this.start_year <= this.end_year) {
      this.years.push(this.start_year++)
    }
  }

}
