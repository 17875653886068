import { Component, OnInit } from '@angular/core';
import { Services } from '../services/services';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Alert } from '../Helpers/alert';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-header',
  templateUrl: '../views/elements/header.component.html'
})
export class HeaderComponent implements OnInit {
  notification_flag = 0
  others: any = []
  services_due: any = []
  due_31: any = []
  due_61: any = []
  isLoggedin: boolean
  toggle: boolean = false
  error = ''
  img_src = ''
  username: string
  subscription_status: string
  // notifications: any = []
  notification_detail = ''
  notification_status_currentmonth: boolean
  notification_status_nextmonth: boolean
  // notification_length = 0
  show_detailed_notification = false
  show_notification = false
  constructor(private _service: Services, private route: ActivatedRoute, private router: Router, private alert_message: Alert, private active_modal: NgbActiveModal) {
    this.subscribe(this)
    this.subscribeNotification(this)
    this.subscribeuserimage(this)
    this.subscribeuserdetail(this)
    //this.subscribeLoggedOut(this)
  }

  ngOnInit() {

    this.router.events.subscribe((res) => {
      if (this.router.url == "/login" || this.router.url == "/register" || this.router.url.includes("passwordreset")) {
        this.isLoggedin = false
      }
      else {
        this.isLoggedin = true
      }
    })
    this.username = localStorage.getItem("username")
    var access_token = localStorage.getItem("token");
    this.img_src = localStorage.getItem("profile_image")
    if (access_token != null) {
      //this.isLoggedin = false;
      // } else {
      //  this.isLoggedin = true;
      // console.log(this.isLoggedin)
      this._service.get_notification('notification').subscribe(
        (response) => {
          if (response.data.red == 1) {
            this.notification_status_currentmonth = true
          }
          if (response.data.green == 1) {
            this.notification_status_nextmonth = true
          }
          // this.notifications = response.data.unseen
          // this.notification_length = response.data.totalunseen
          // this.setlist()
          localStorage.setItem("notification_date", new Date(Date.now()).toString())
        },
        (error) => { console.log(error) })
    }
  }
  subscribeuserimage(_this) {
    _this._service.editprofileimage.subscribe(function (value) {
      _this.img_src = value
    });
  }
  subscribeuserdetail(_this) {
    _this._service.editprofile.subscribe(function (value) {
      _this.username = value.first_name + " " + value.last_name
    });
  }

  subscribe(_this) {
    _this._service.loggedInEmitter.subscribe(function (value) {

      if (value != '') {
        _this.isLoggedin = true;
        _this.username = value.name.toString();
        _this.img_src = value.profile_image
      }
    });
  }
  // subscribeLoggedOut(_this) {
  //   _this._service.loggedOutEmitter.subscribe(function (value) {
  //     _this.isLoggedin = value;
  //     console.log(_this.isLoggedin)
  //   });
  // }

  subscribeNotification(_this) {
    _this._service.notification.subscribe(function (value) {
      if (value.red == 1) {
        _this.notification_status_currentmonth = true;
      }
      else {
        _this.notification_status_currentmonth = false;
      }
      if (value.green == 1) {
        _this.notification_status_nextmonth = true;
      }
      else {
        _this.notification_status_nextmonth = false;
      }

      // localStorage.setItem("notification_date", new Date(Date.now()).toString())
    });
  }


  logout() {
    this._service.logout('logout').subscribe(
      (response) => {
        this.notification_status_currentmonth=false
        this.notification_status_nextmonth=false
        this.isLoggedin = false;
        localStorage.clear();
      
        this.router.navigate(['/']);
        
      },
      error => {
        if (error.status != '401') {
          this.error = error.error.message
          this.alert_message.alert_msg(this.error)
        }
        else {
          this.router.navigate(['/']);
          this.isLoggedin = false;
          localStorage.clear();
        }

      }
    )

  }
  // openPopup(id) {
  //   localStorage.setItem("selected_id", id)
  //   this.show_detailed_notification = true

  //   this.notifications.forEach(element => {
  //     if (element.id == id) {
  //       this.notification_detail = element.notification
  //       // this.mark_complete(id)
  //     }
  //   });


  // }
  // mark_complete() {
  //   let notification_list: any[] = []


  //   var id = localStorage.getItem("selected_id")
  //   this._service.changenotificationstatus('changenotificationstatus', { ids: id }).subscribe(
  //     (response) => {
  //       this.notifications.forEach(element => {
  //         if (element.id != id) {
  //           notification_list.push(element)
  //         }
  //       })

  //       if (notification_list.length == 0)
  //         this.notification_length = 0

  //       this.notifications = notification_list
  //       this.notification_length=this.notifications.length
  //       this.setlist()

  //       this.alert_message.alert_msg("Status successfully changed!")
  //       this.closeModal()
  //     },
  //     error => {
  //       this.alert_message.showErrorMessage("Please try again.")
  //     })
  //   localStorage.removeItem("selected_id")
  // }

  // setlist() {
  //   // console.log("fired")
  //   this.services_due = []
  //   this.due_31 = []
  //   this.due_61 = []
  //   this.others = []

  //   this.notifications.forEach(element => {
  //     if (element.notification_title == "Service Due from 31 days.") {
  //       this.due_31.push(element)
  //     }
  //     else if (element.notification_title == "Service Due from 61 days.") {
  //       this.due_61.push(element)
  //     }
  //     else if (element.notification_title == "Service Due") {
  //       this.services_due.push(element)
  //     }
  //     else {
  //       this.others.push(element)
  //     }
  //   });

  // }
  //on toggle button click in responsive view
  onclick() {
    this.toggle = !this.toggle
    this._service.toggle_class.emit(this.toggle)
  }

  closeModal() {
    this.show_detailed_notification = false;
  }
  notification() {
    let uri: string = this.router.url
    if (uri.includes('reports')) {
      //  console.log("Here")
      this._service.notification_flag.emit('0')
    }
    // this.notification_flag=0
    // localStorage.setItem("notification_flag",this.notification_flag.toString())
    // this.show_notification=!this.show_notification
    //this.router.navigate(['/reports?param=1'])
    else {
      this.router.navigate(['/reports/0'])
    }
  }
  nextMonthNotification() {
    let uri: string = this.router.url
    if (uri.includes('reports')) {
      this._service.notification_flag.emit('1')
    }
    else {
      this.router.navigate(['/reports/1'])
    }
  }
}
