import { Component, OnInit } from '@angular/core';
import { Services } from '../services/services';
import { DomSanitizer } from '@angular/platform-browser';
import { Alert } from '../Helpers/alert';

@Component({
  selector: 'app-payment-popup',
  templateUrl: './payment-popup.component.html',
  styleUrls: ['./payment-popup.component.css']
})
export class PaymentPopupComponent implements OnInit {
  isLoaderVisible = false;
  p_url = ""
  constructor(private sanitizer: DomSanitizer, private service: Services, private alert_message: Alert) { }

  ngOnInit() {
    var value = localStorage.getItem("Url");
    localStorage.removeItem("Url")
    this.p_url = value;
  }
  photoURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.p_url);
  }

}
