import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http/src/interceptor';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Alert } from '../Helpers/alert';
import { catchError } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class InterceptService implements HttpInterceptor {
    routeTo = ''
    constructor(private router: Router, private alert_message: Alert, private route: ActivatedRoute) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.routeTo = this.router.url
        const token = localStorage.getItem('token')
        if (token != null) {
            const request = req.clone({
                headers: req.headers
                    .set("Authorization", "bearer " + token)
            })
            return next.handle(request).pipe(
                catchError(error => {
                    // checks if a url is to an admin api or not
                    if (error.status == '401') {
                        localStorage.clear()
                        localStorage.setItem("routeTo", this.routeTo)
                        this.alert_message.showErrorMessage("Something went wrong.Please login again to continue!")
                        this.router.navigate(['/login'])
                        // attempting to refresh our token
                    }
                    return throwError(error);
                }));
        }
        else {
            const request = req.clone({
                headers: req.headers
                    .set("Content-Type", "application/json")
            })
            return next.handle(request).pipe(
                catchError(error => {
                    // checks if a url is to an admin api or not
                    if (error.status == '401') {
                        localStorage.clear()
                        localStorage.setItem("routeTo", this.routeTo)
                        this.alert_message.showErrorMessage("Something went wrong.Please login again to continue!")
                        this.router.navigate(['/login'])
                        // attempting to refresh our token
                    }
                    return throwError(error);
                }));
        }
    }
}
