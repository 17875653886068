// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  apiUrl:
  //  'http://34.236.234.70/admin/public/api/v1.0/'
  // 'http://192.168.3.170:8000/api/v1.0/'
  // 'http://54.198.64.235/admin/public/api/v1.0/'
  'https://www.hvacmt.com/admin/public/api/v1.0/'
  //  'http://local.hvacmt.jk/admin/public/api/v1.0/'
};
/*
* In development mode, for easier debugging, you can ignore zone related error
* stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
* below file. Don't forget to comment it out in production mode
* because it will have a performance impact when errors are thrown
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.