import { Component, OnInit } from '@angular/core';
import { Services } from '../services/services';

@Component({
  selector: 'app-right-tab',
  templateUrl: '../views/elements/right-tab.component.html'
})
export class RightTabComponent implements OnInit {
  adlist: any
  image=[];
  constructor(private _service: Services) { }
  ngOnInit() {
    let i=0
    this._service.getadsdetail('adslist').subscribe(
      response => {
        this.adlist = response.data.ads
        // this.adlist.forEach(element => {
        //   if (element.google_ads_setting == 0) {
        //     this.image[i] = true
        //     // this.src.push(element.imagepath)
        //   }
        //   else {
        //     this.image[i] = false
        //   }
        //   i++;
        // });
      }, error => {
        console.log(error)
      }
    )
  }
}
