import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Services } from '../services/services';
import { validateEmail } from '../Helpers/validations';
import { LoginUsers } from '../models/login_users';
import { Alert } from '../Helpers/alert';

@Component({
  selector: 'app-login',
  templateUrl: '../views/log-in.component.html'
})

export class LogInComponent implements OnInit {

  submitted = false
  email_submitted = false
  user: LoginUsers;
  response: any;
  token = '';
  public error: any
  public forgot_popup_error: any
  log: FormGroup;
  forgot_popup: FormGroup;
  email: string = '';
  div_error: boolean = false
  username: string
  isLoaderVisible = false;
  isLoaderVisible1 = false;
  @ViewChild('closeBtn') closeBtn: ElementRef;

  constructor(private alert_message: Alert, private formBuilder: FormBuilder, private router: Router, private user_service: Services) {

  }
  ngOnInit() {
    if (localStorage.getItem("token")) {
      localStorage.setItem("active_option", "Customer")
      this.router.navigate(['/customer'])
    }
    this.createForm();
  }
  get f() {
    return this.log.controls;
  }


  createForm() {
    this.log = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, validateEmail])],
      password: ['', Validators.required]
    })
    this.forgot_popup = this.formBuilder.group({
      f_email: new FormControl()
    })
  }

  onSubmit(valid) {
    var url:any
    this.submitted = true
    this.isLoaderVisible = true;
    if (valid) {
      this.user = {
        email: this.log.get('email').value,
        password: this.log.get('password').value
      }
      this.user_service.loginService('login', this.user).subscribe(
        (response) => {
          this.response = response
          if (this.response) {
            localStorage.setItem('username', this.response.name)
            localStorage.setItem('profile_image', this.response.profile_image)
            localStorage.setItem("subscription_status", this.response.is_subscription_complete)
            localStorage.setItem('token', this.response.access_token)
            this.isLoaderVisible = false;
            this.user_service.loggedInEmitter.emit(this.response)
            url=localStorage.getItem("routeTo")
            if(url!=null){

            this.router.navigate([url])
            localStorage.removeItem("routeTo")
            }
           else{
            this.router.navigate(['/customer']);
           }

          }
        },

        error => {
          this.error = error
          this.isLoaderVisible = false;
          if (error.status != '401') {
            if (error.error.error = "E-mail and password dosen't match")
              this.alert_message.showErrorMessage(error.error.error)
            this.error = error.error.errors
          }
        })

    }
    else {
      this.isLoaderVisible = false;
      this.alert_message.showErrorMessage("Please provide appropriate values")
    }

  }

  sendMail() {
    this.email_submitted = true
    this.isLoaderVisible1 = true;
    if (this.forgot_popup.valid) {
      this.email = this.forgot_popup.controls.f_email.value
      this.user_service.forgotPassword('request-password-reset', this.email).subscribe(
        (response) => {
          this.isLoaderVisible1 = false;
          if (response)
            this.response = response
          this.alert_message.alert_msg("Please check your email to reset your password.")
          this.closeModal();

        },
        error => {
          if (error.error.message) {
            this.alert_message.showErrorMessage(error.error.message)
          }
          this.isLoaderVisible1 = false;
          if (error.status != '401') {
            if (error.error.status = "error") {
              this.div_error = true
              this.forgot_popup_error = error.error.errors
            }
          }
        })

    }
    else {
      this.isLoaderVisible1 = false;
      this.alert_message.showErrorMessage("Please provide appropriate value.")
    }

  }
  //method to close modal
  private closeModal(): void {
    this.closeBtn.nativeElement.click();
  }

  forgotPopup() {
    this.forgot_popup = this.formBuilder.group({
      f_email: ['', Validators.compose([Validators.required, validateEmail])]
    })
  }
}
