import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: '../views/pagination.component.html'
})
export class PaginationComponent implements OnInit {
  page = 1;
  constructor() { }

  ngOnInit() {
  }

}
