import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrum',
  templateUrl: '../views/elements/breadcrum.component.html'
})
export class BreadcrumComponent implements OnInit {
  menuoption: string
  @Input() url: string;
  constrsuctor() {
  }

  ngOnInit() {
    this.menuoption = this.url
  }
}
