import { Component, OnInit } from '@angular/core';
import { Services } from '../services/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Alert } from '../Helpers/alert';


@Component({
  selector: 'app-show-equipment-image',
  templateUrl: '../views/show-equipment-image.component.html'
})
export class ShowEquipmentImageComponent implements OnInit {
  isLoaderVisible = false
  equipmentid
  customerid
  propertyid
  images: any;
  url;
  deleteIndex = -1;
  show_confirm = false
  frameimage: any;
  image_name = ''
  imageid = ''
  constructor(private _service: Services, private activemodal: NgbActiveModal, private alert: Alert) { }

  ngOnInit() {
    this.isLoaderVisible = true
    this.equipmentid = localStorage.getItem('e_id');
    this.customerid = localStorage.getItem('c_id')
    this.propertyid = localStorage.getItem('p_id')
    this._service.get_image('equipmentimages', { equipmentid: this.equipmentid }).subscribe(
      response => {
        if (response.data.image_found === '1') {
          this.images = response.data.equipmentimageslist;
          this.frameimage = this.images[0].image_path;
        } else if (response.data.image_found === '0') {
          this.activemodal.close();
          this.alert.alert_msg(response.message);
        }
        this.isLoaderVisible = false
      },
      error => {
        this.isLoaderVisible = false
        this.alert.alert_msg("Something went wrong, please try again.")
        this.closeModal()
      }
    );
  }
  closeModal() {
    localStorage.removeItem('e_id');
    this.activemodal.close();
  }

  showConfirmDialog(id, imagename, index) {
    swal("Are you sure you want to do this?", {
      buttons: {
        Delete: {
          value: "catch",
        },
        Cancel: true,
      },

    })
      .then((value) => {
        switch (value) {
          case "catch":
            this.deleteIndex = index;
            this.image_name = imagename
            this.imageid = id
            this.deleteEquipmentImage()
            break;
        }
      });
  }

  deleteEquipmentImage() {
    this._service.delete_Equipment_image('deleteequipmentimage', {
      imageid: this.imageid,
      equipmentid: this.equipmentid,
      imagename: this.image_name,
    }).subscribe(
      response => {
        this.alert.alert_msg('Image removed successfully.');
        if (this.deleteIndex >= 0) {
          this.images.splice(this.deleteIndex, 1);
          if (this.images[0]) {
            this.frameimage = this.images[0]['image_path'];
            this.deleteIndex = -1;
          }
          else {
            this.closeModal()
          }
        }
      },
      error => {
        this.alert.alert_msg('Something went wrong, please try again.');
      }
    );
  }
  changeframeimage(src) {
    this.frameimage = src;
  }

}
