import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Services } from '../services/services';
import { Alert } from '../Helpers/alert';

@Component({
  selector: 'app-notification',
  templateUrl: '../views/notification.component.html'

})
export class NotificationComponent implements OnInit {
  _message = "Notification"
  isLoaderVisibleNotify = false
  show_detail=false
  notification_values: any
  notify_customer: number
  notify_contractor_before_days: number
  notify_customer_before_days: number
  notification_settings: FormGroup
  looparray = []
  @ViewChild('closeBtnNotify') closeBtnNotify: ElementRef;
  constructor(private editInfo: Services, private alert_message: Alert, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.isLoaderVisibleNotify=true
    this.createForm_NotificationSettings()
    this.editInfo.edit_user_info('userprofile').subscribe(
      (response) => {
        this.isLoaderVisibleNotify = false;
        this.show_detail=true
        this.notify_customer = response.data.notify_customer
        this.notify_contractor_before_days = response.data.notify_contractor_before_days
        this.notify_customer_before_days = response.data.notify_customer_before_days
      },
      error => {
        this.isLoaderVisibleNotify = false;
        if (error.status != '401')
          this.alert_message.showErrorMessage(error.error.message)
      })
    let loop_index = 1
    for (loop_index == 1; loop_index <= 60; loop_index++) {
      this.looparray.push(loop_index)
    }
   
  }
  createForm_NotificationSettings() {
    this.notification_settings = this.formBuilder.group({
      notify_contractor_before_days: [30],
      notify_customer: ['1'],
      notify_customer_before_days: [7]
    })
  }

  //Code to change notification settings starts
  changeNotificationSettings() {
    this.isLoaderVisibleNotify = true
    this.notification_values = {
      notify_customer: this.notification_settings.controls.notify_customer.value,
      notify_contractor_before_days: this.notification_settings.controls.notify_contractor_before_days.value,
      notify_customer_before_days: this.notification_settings.controls.notify_customer_before_days.value
    }
    this.editInfo.changeNotificationSettings('changenotificationsetting', this.notification_values).subscribe(
      response => {
        this.notify_customer = this.notification_settings.controls.notify_customer.value
        this.notify_contractor_before_days = this.notification_settings.controls.notify_contractor_before_days.value
        this.notify_customer_before_days = this.notification_settings.controls.notify_customer_before_days.value
        this.alert_message.alert_msg("Notification settings updated.")
        this.isLoaderVisibleNotify = false
      },
      error => {
        this.alert_message.showErrorMessage("Please try again.")
        this.isLoaderVisibleNotify = false
      }
    )
    this.closeModal();
  }

  //Code to change notification settings ends
  closeModal(): void {
    this.closeBtnNotify.nativeElement.click();
  }
}
