import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Services } from '../services/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from '../Helpers/alert';
import { passwordsLength, matchingPasswords } from '../Helpers/validations';

@Component({
  selector: 'app-resetpassword',
  templateUrl: '../views/resetpassword.component.html'
})

export class ResetpasswordComponent implements OnInit {
  change_password: FormGroup;
  token = ''
  submitted = false
  error: any

  constructor(private alert: Alert, private router: Router, private formBuilder: FormBuilder, private _service: Services, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams._token
    this.createForm()
    this._service.resetPassword('checkmailtoken', this.token).subscribe(
      response => {
      },
      error => {
        if (error.message == "Token is not in our database.") {
          this.alert.showErrorMessage("Invalid token. Please try again.")
          this.router.navigate(['/login'])
        }
      })
  }

  createForm() {
    this.change_password = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, passwordsLength])],
      confirm_password: ['', Validators.required]
    },
      {
        validator: matchingPasswords('password', 'confirm_password')
      })
  }

  onSubmit() {
    this.submitted = true
    if (this.change_password.valid) {
      this._service.response_resetPassword('response-password-reset',
        { _token: this.token, password: this.change_password.controls.password.value, password_confirmation: this.change_password.controls.confirm_password.value }).subscribe(
          response => {
            this.alert.alert_msg("Password updated successfully.")
            this.router.navigate(['/login'])
          }, error => {
            this.error = error.error.errors
          })
    }
    else {
      this.alert.showErrorMessage("Please provide appropriate values.")
    }
  }
}
