import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { Services } from '../services/services';
import { Customer } from '../models/customer';
import { ActivatedRoute, Router } from '@angular/router';
import { AddPropertyComponent } from './add-property.component';
import { AddEquipmentComponent } from './add-equipment.component';
import { ShowEquipmentImageComponent } from './show-equipment-image.component';
import { Validators, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Alert } from '../Helpers/alert';
import { Property } from '../models/property';
import { validatePhnNumber, validatePhone, isvalidDate } from '../Helpers/validations';
import { Equipment } from '../models/equipment';
import { DatepickerOptions } from 'ng2-datepicker';
import * as frLocale from 'date-fns/locale/fr';
import { ShowPropertyImageComponent } from './show-property-image.component';
import { addYears } from 'date-fns';
import { DecimalPipe } from '@angular/common';
@Component({
  selector: 'app-customer-detail',
  templateUrl: '../views/customer-detail.component.html'

})
export class CustomerDetailComponent implements OnInit {

  property_index = -1
  inspection_detail: any;
  editcustom_filter_flag=false
  _message = "Customer/Customer Detail";
  zoom: number = 8;
  due = true
  download_url = ''
  previous: any
  isEditCustomerLoader = false;
  isLoaderVisible = false;
  [x: string]: any;
  customerForm: FormGroup;
  email: FormArray;
  filter_size: FormArray
  equipmentinspections: FormArray
  phone_number: FormArray;
  contactArray: FormArray;
  edit_equiment_submitted = false
  submitted = false;
  theCheckbox = false;
  cotractfiles: any
  isEditPropertyLoaderVisible = false
  isEditEquipmentLoader = false
  customer: Customer;
  deletePropertyIndex = -1;
  deleteEquipmentId = 0;
  deleteEquipmentPropertyId = 0;
  deleteEquipmentCustomerId = 0;
  deleteEquipmentPropertyIndex = -1;
  no_of_inspection: any
  collapse = true
  filter_sizes = [
    '8 x 16 x 4',
    '8 x 30 x 4',
    '10 x 10 x 4',
    '10 x 15 x 4',
    '10 x 16 x 4',
    '10 x 20 x 4',
    '10 x 24 x 4',
    '10 x 25 x 4',
    '12 x 12 x 4',
    '12 x 16 x 4',
    '12 x 18 x 4',
    '12 x 20 x 4',
    '12 x 24 x 4',
    '12 x 25 x 4',
    '12 x 27 x 4',
    '12 x 30 x 4',
    '12 x 36 x 4',
    '14 x 14 x 4',
    '14 x 18 x 4',
    '14 x 20 x 4',
    '14 x 24 x 4',
    '14 x 25 x 4',
    '14 x 30 x 4',
    '14 x 36 x 4',
    '15 x 20 x 4',
    '15 x 25 x 4',
    '15 x 30 x 4',
    '16 x 16 x 4',
    '16 x 20 x 4',
    '16 x 24 x 4',
    '16 x 25 x 4',
    '16 x 30 x 4',
    '16 x 36 x 4',
    '17 x 20 x 4',
    '17 x 22 x 4',
    '17 x 25 x 4',
    '18 x 18 x 4',
    '18 x 20 x 4',
    '18 x 22 x 4',
    '18 x 24 x 4',
    '18 x 25 x 4',
    '18 x 30 x 4',
    '18 x 36 x 4',
    '19 x 21 x 4',
    '20 x 20 x 4',
    '20 x 22 x 4',
    '20 x 23 x 4',
    '20 x 24 x 4',
    '20 x 25 x 4',
    '20 x 30 x 4',
    '20 x 36 x 4',
    '21 x 22 x 4',
    '22 x 22 x 4',
    '22 x 24 x 4',
    '24 x 24 x 4',
    '24 x 30 x 4',
    '24 x 36 x 4',
    '25 x 25 x 4',
    '25 x 28 x 4',
    '25 x 29 x 4',
    '25 x 32 x 4',
    '27 x 27 x 4',
    '28 x 30 x 4',
    '29 x 29 x 4',
    '8 x 16 x 1',
    '8 x 30 x 1',
    '10 x 10 x 1',
    '10 x 15 x 1',
    '10 x 16 x 1',
    '10 x 20 x 1',
    '10 x 24 x 1',
    '10 x 25 x 1',
    '12 x 12 x 1',
    '12 x 16 x 1',
    '12 x 18 x 1',
    '12 x 20 x 1',
    '12 x 24 x 1',
    '12 x 25 x 1',
    '12 x 27 x 1',
    '12 x 30 x 1',
    '12 x 36 x 1',
    '14 x 14 x 1',
    '14 x 18 x 1',
    '14 x 20 x 1',
    '14 x 24 x 1',
    '14 x 25 x 1',
    '14 x 30 x 1',
    '14 x 36 x 1',
    '15 x 20 x 1',
    '15 x 25 x 1',
    '15 x 30 x 1',
    '16 x 16 x 1',
    '16 x 20 x 1',
    '16 x 24 x 1',
    '16 x 25 x 1',
    '16 x 30 x 1',
    '16 x 36 x 1',
    '17 x 20 x 1',
    '17 x 22 x 1',
    '17 x 25 x 1',
    '18 x 18 x 1',
    '18 x 20 x 1',
    '18 x 22 x 1',
    '18 x 24 x 1',
    '18 x 25 x 1',
    '18 x 30 x 1',
    '18 x 36 x 1',
    '19 x 21 x 1',
    '20 x 20 x 1',
    '20 x 22 x 1',
    '20 x 23 x 1',
    '20 x 24 x 1',
    '20 x 25 x 1',
    '20 x 30 x 1',
    '20 x 36 x 1',
    '21 x 22 x 1',
    '22 x 22 x 1',
    '22 x 24 x 1',
    '24 x 24 x 1',
    '24 x 30 x 1',
    '24 x 36 x 1',
    '25 x 25 x 1',
    '25 x 28 x 1',
    '25 x 29 x 1',
    '25 x 32 x 1',
    '27 x 27 x 1',
    '28 x 30 x 1',
    '29 x 29 x 1',
    '30 x 30 x 1',
    '8 x 16 x 2',
    '8 x 30 x 2',
    '10 x 10 x 2',
    '10 x 15 x 2',
    '10 x 16 x 2',
    '10 x 20 x 2',
    '10 x 24 x 2',
    '10 x 25 x 2',
    '12 x 12 x 2',
    '12 x 16 x 2',
    '12 x 18 x 2',
    '12 x 20 x 2',
    '12 x 24 x 2',
    '12 x 25 x 2',
    '12 x 27 x 2',
    '12 x 30 x 2',
    '12 x 36 x 2',
    '14 x 14 x 2',
    '14 x 18 x 2',
    '14 x 20 x 2',
    '14 x 24 x 2',
    '14 x 25 x 2',
    '14 x 30 x 2',
    '14 x 36 x 2',
    '15 x 20 x 2',
    '15 x 25 x 2',
    '15 x 30 x 2',
    '16 x 16 x 2',
    '16 x 20 x 2',
    '16 x 24 x 2',
    '16 x 25 x 2',
    '16 x 30 x 2',
    '16 x 36 x 2',
    '17 x 20 x 2',
    '17 x 22 x 2',
    '17 x 25 x 2',
    '18 x 18 x 2',
    '18 x 20 x 2',
    '18 x 22 x 2',
    '18 x 24 x 2',
    '18 x 25 x 2',
    '18 x 30 x 2',
    '18 x 36 x 2',
    '19 x 21 x 2',
    '20 x 20 x 2',
    '20 x 22 x 2',
    '20 x 23 x 2',
    '20 x 24 x 2',
    '20 x 25 x 2',
    '20 x 30 x 2',
    '20 x 36 x 2',
    '21 x 22 x 2',
    '22 x 22 x 2',
    '22 x 24 x 2',
    '24 x 24 x 2',
    '24 x 30 x 2',
    '24 x 36 x 2',
    '25 x 25 x 2',
    '25 x 28 x 2',
    '25 x 29 x 2',
    '25 x 32 x 2',
    '27 x 27 x 2',
    '28 x 30 x 2',
    '29 x 29 x 2',
    '30 x 30 x 2'
  ]
  f_size_value=false
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('closePropBtn') closePropBtn: ElementRef;
  @ViewChild('closeDltEquipBtn') closeDltEquipBtn: ElementRef;
  @ViewChild('closeEditEquip') closeEditEquip: ElementRef;
  @ViewChild('closeDltPropBtn') closeDltPropBtn: ElementRef;
  @ViewChild('closeContractBtn') closeContractBtn: ElementRef;
  @ViewChild('closeInspectionBtn') closeInspectionBtn: ElementRef;

  property_limit_left: number
  marked = false;
  checkbox_val;
  customvalue='';
  //By bhatia sir
  // contract_start_date: Date = new Date()
  // contract_end: Date = new Date()
  //by bhatia sir
  property: FormGroup;
  editproperty: Property;
  property_details;
  property_id;
  property_submitted = false;
  i = 0;
  delete_property_id;
  show_map1 = false
  show_map2 = false
  contract_date: Date = new Date()
  contract_end_date: Date = addYears(Date.now(), 1);
  customerid: any;
  response: any;
  info: Customer;
  properties: any[];
  equipments: any = [];
  property_detail = false;
  propertydetail;
  lat: any;
  lng: any;
  selectedequipmentindex: number = -1;
  customer_error: any
  property_error: any
  equipment_error: any
  propertylat: any;
  propertylng: any;
  equipment: FormGroup;
  editEquipment: Equipment;
  equipment_id: number
  myFiles: File[] = []
  myFiles1: File[] = []
  imagesname = '';
  contractfile = ''
  phone_detail: any
  inspection_detail_count = [];
  years = [];
  days = [];
  image;
  adlist: any
  imgsrc = ""
  collapseExpanDivArr: boolean[] = [];
  options: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,

    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: frLocale,
    minDate: new Date(Date.now()), // Minimal selectable date
    maxDate: new Date(Date.now()),  // Maximal selectable date
    barTitleIfEmpty: 'Click to select a date',
    placeholder: 'Click to select a date', // HTML input placeholder attribute (default: '') 
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
  };

  @Input() id: number;

  constructor(private alert: Alert,
    private formBuilder: FormBuilder,
    private _service: Services,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private _decimalPipe: DecimalPipe
  ) {
    this.subscribeAddEquipment(this);
    this.subscribeAddProperty(this);
  }

  ngOnInit() {
    this.image = false
    this.isLoaderVisible = true
    this.days = []
    let i = 0
    for (i = 1; i <= 31; i++) {
      this.days.push(i);
    }

    this.createForm();
    this.customerid = this.route.snapshot.params.id;
    this._service.customer_detail('customerdetail/' + this.customerid.toString()).subscribe(
      response => {
        this.adlist = response.data.ad
        if (this.adlist.google_ads_setting == 0) {
          this.image = true
          this.imgsrc = this.adlist.imagepath
        }
        else {
          this.image = false
        }
        this.property_limit_left = response.data.remainproperty
        this.checkbox_val = response.data.customerdetail.same_as_billing;
        this.info = response.data.customerdetail;
        this.properties = response.data.properties;
        this.customerid = response.data.customerdetail.id;
        if (this.properties.length > 0) {
          this.lat = parseFloat(this.properties[0].lat);
          this.lng = parseFloat(this.properties[0].lng);
        }
        this.loadList();
      },
      error => {
        this.isLoaderVisible = false
      }
    );
  }
  loadList() {
    let indx = 0
    let propertyindex;
    let propertyid;
    if (this.route.snapshot.params.pid) {
      propertyid = this.route.snapshot.params.pid
    }
    if (this.route.snapshot.params.index != undefined) {
      propertyindex = this.route.snapshot.params.index;
      this.property_index = this.route.snapshot.params.index
    }
    if (propertyid != undefined) {
      if (propertyindex == undefined) {
        this.properties.forEach(element => {
          if (element.id != propertyid) {
            indx++;
          }
          else {
            propertyindex = indx;
            return true;
          }
        });
      }
      this.showEquipments(propertyid, propertyindex)
    }
    this.isLoaderVisible = false
  }
  validateNumber(num) {
    if (!validatePhnNumber(num)) {
      return true
    }
  }

  formatText(event: any) {
    if(event.target.value == "") {
      return;
    }
    let txt = event.target.value;
    if(event.type === 'focus') {
      event.target.value = this.clearNumberFormat(txt);
    } else if(event.type === 'blur') {
      event.target.value = this.addNumberFormat(txt);
    }
  }

  addNumberFormat(number: string) {
    return '$' + this._decimalPipe.transform((Number(number)).toFixed(2),"1.2-2");
  }

  clearNumberFormat(number: string) {
    return number.replace(/\$|,/g, '')
  }

  phnvalueChanged(e) {
    var phn_val: string = e.target.value
    var num = phn_val.charAt(phn_val.length - 1)
    if (this.validateNumber(num))
      e.target.value = phn_val.substring(0, phn_val.length - 1)
    else if (phn_val.length == 3 || phn_val.length == 7)
      e.target.value = phn_val + "-"

  }

  subscribeAddEquipment(instance) {
    this._service.addEquipmentEmitter.subscribe(function (value) {
      instance.equipments.push(value.equipmentdetail);
      instance.properties[instance.property_index].property_service_due_status = value.duestatus.property_status

    });
  }


  subscribeAddProperty(instance) {
    instance.property_limit_left--;
    this._service.addPropertyEmitter.subscribe(function (value) {
      instance.lat = parseFloat(value.lat);
      instance.lng = parseFloat(value.lng);
      instance.properties.push(value);
    });
  }

  createForm() {
    this.customerForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: '',
      company_name: '',
      billing_address1: '',
      billing_address2: '',
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      same_as_billing: '',
      shipping_address1: '',
      shipping_address2: '',
      shipping_city: '',
      shipping_state: '',
      shipping_zip: '',
      email: this.formBuilder.array([]),
      phone_number: this.formBuilder.array([])
    });
    this.property = this.formBuilder.group({
      building_name: ['', Validators.required],
      address1: ['', Validators.required],
      address2: '',
      zip_code: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      contract_type: [''],
      contract_amount: [''],
      contract_date: ['', Validators.required],
      contract_end_date: ['', Validators.required],
      no_of_inspections: ['', Validators.required],
      inspectiondetail: this.formBuilder.array([]),
      contract_term: ['', Validators.required],
      contract_term_type: ['', Validators.required],
      contract_billing: ['', Validators.required],
      contract_billing_custom: [''],
      contract_file: '',
      propertyimages: ''
    });

    this.equipment = this.formBuilder.group({
      
      aircondition_area: ['', Validators.required],
      aircondition_area_other: '',
      equipment: ['', Validators.required],
      location_of_equipment: ['', Validators.required],
      filter_size: this.formBuilder.array([]),
      belt_size: [],
      manufacturer_name: [],
       model_number: ['', Validators.required],
      serial_number: ['', Validators.required],
      equipmentimages: [],
      additional_notes: '',
      filter_size_custom: ['']

    });
  }

  createItem(val): FormGroup {
    if (val === 'email') {
      return this.formBuilder.group({
        id: [0],
        mail: ['', Validators.email]
      });

    } else if (val === 'phn') {
      return this.formBuilder.group({
        id: [0],
        phn: ['', validatePhone],
        type: '',
        contact_name: ''
      });
    }
  }
  setVals() {
    this.customerForm.patchValue(
      {
        first_name: this.info.first_name,
        last_name: this.info.last_name,
        company_name: this.info.company_name,
        billing_address1: this.info.billing_address1,
        billing_address2: this.info.billing_address2,
        billing_city: this.info.billing_city,
        billing_state: this.info.billing_state,
        billing_zip: this.info.billing_zip,
        shipping_address1: this.info.shipping_address1,
        shipping_address2: this.info.shipping_address2,
        shipping_city: this.info.shipping_city,
        shipping_state: this.info.shipping_state,
        shipping_zip: this.info.shipping_zip
      }
    );

    if (this.checkbox_val != 0) {
      this.theCheckbox = true;
      this.marked = true;
    }
    this.setContactNumber(this.info.phone_number);
    this.setEmail(this.info.email);
  }

  setContactNumber(phoneNumbers) {
    const contactNumberFGs = [];
    if (phoneNumbers.length > 0) {
      phoneNumbers.forEach(contactNumber => {
        contactNumberFGs.push(this.formBuilder.group({
          id: [contactNumber.id],
          phn: [contactNumber.phn, validatePhone],
          type: [contactNumber.type],
          contact_name: contactNumber.contact_name
        }));
      });
    }
    else {
      this.createItem('phn')
      contactNumberFGs.push(this.formBuilder.group({
        id: [0],
        phn: ['', validatePhone],
        type: '',
        contact_name: ''
      }))
    }
    // phoneNumbers.forEach(contactNumber => {
    //   if (contactNumber.phn == null) {
    //     contactNumberFGs.push(this.formBuilder.group({
    //       id: [contactNumber.id],
    //       phn: [contactNumber.phn, validatePhone],
    //       type: "",
    //       contact_name: ""
    //     }));
    //   }
    //   else {
    //     contactNumberFGs.push(this.formBuilder.group({
    //       id: [contactNumber.id],
    //       phn: [contactNumber.phn, validatePhone],
    //       type: [contactNumber.type],
    //       contact_name: contactNumber.contact_name
    //     }));
    //   }
    // });

    this.customerForm.setControl('phone_number', this.formBuilder.array(contactNumberFGs));
  }

  setEmail(emails) {
    const email_ids = [];
    if (emails.length > 0) {
      emails.forEach(id => {
        email_ids.push(this.formBuilder.group({
          id: [id.id],
          mail: [id.mail, Validators.email]
        }));
      });
      this.customerForm.setControl('email', this.formBuilder.array(email_ids));
    }
    else {
      this.createItem('email')
      email_ids.push(this.formBuilder.group({
        id: [0],
        mail: ['', Validators.email]
      }));
      this.customerForm.setControl('email', this.formBuilder.array(email_ids));
    }
  }

  addEmail(): void {
    this.email = this.customerForm.get('email') as FormArray;
    this.email.push(this.createItem('email'));
  }

  addPhn() {
    this.phone_number = this.customerForm.get('phone_number') as FormArray;
    this.phone_number.push(this.createItem('phn'));
  }


  removeEmail(i: number, val, id) {
    if (id != 0) {
      this._service.delete_email('deletecustomeremail', { emailid: id, customerid: this.customerid }).subscribe(
        response => {
        },
        error => {
          this.alert.alert_msg("Something went wrong.Please try again.")
        })
    }
    this.removeUnit(i, val)
  }

  removePhone(i: number, val, id) {
    if (id != 0) {
      this._service.delete_phone('deletecustomerphone', { phoneid: id, customerid: this.customerid }).subscribe(response => {

      },
        error => {

          this.alert.alert_msg("Please try again.")
        })
    }

    this.removeUnit(i, val)

  }

  removeUnit(i: number, val) {
    const control = <FormArray>this.customerForm.controls[val];
    control.removeAt(i);
  }

  toggleVisibility(e) {
    this.marked = e.target.checked;
    if (!this.marked && !this.info.shipping_address1) {
      this.customerForm.controls.shipping_address1.setValue('');
      this.customerForm.controls.shipping_address2.setValue('');
      this.customerForm.controls.shipping_city.setValue('');
      this.customerForm.controls.shipping_state.setValue('');
      this.customerForm.controls.shipping_zip.setValue('');
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.customerForm.controls; }

  onSubmit() {
    this.isEditCustomerLoader = true
    if (this.customerForm.get('same_as_billing').value) {
      this.customerForm.controls.shipping_address1.setValue(this.customerForm.get('billing_address1').value);
      this.customerForm.controls.shipping_address2.setValue(this.customerForm.get('billing_address2').value);
      this.customerForm.controls.shipping_city.setValue(this.customerForm.get('billing_city').value);
      this.customerForm.controls.shipping_state.setValue(this.customerForm.get('billing_state').value);
      this.customerForm.controls.shipping_zip.setValue(this.customerForm.get('billing_zip').value);
    }
    this.submitted = true;
    let index = 0;
    (this.customerForm.get('email').value).forEach(element => {
      if (element.mail == "") {
        (this.customerForm.get('email').value).splice(index, 1)
      }
      index = index + 1;
    });
    index = 0;
    (this.customerForm.get('phone_number').value).forEach(element => {
      if (element.phn == "") {
        (this.customerForm.get('phone_number').value).splice(index, 1)
      }
      index = index + 1;
    });
    this.customer = {
      first_name: this.customerForm.get('first_name').value,
      last_name: this.customerForm.get('last_name').value,
      company_name: this.customerForm.get('company_name').value,
      billing_address1: this.customerForm.get('billing_address1').value,
      billing_address2: this.customerForm.get('billing_address2').value,
      billing_city: this.customerForm.get('billing_city').value,
      billing_state: this.customerForm.get('billing_state').value,
      billing_zip: this.customerForm.get('billing_zip').value,
      same_as_billing: this.customerForm.get('same_as_billing').value,
      shipping_address1: this.customerForm.get('shipping_address1').value,
      shipping_address2: this.customerForm.get('shipping_address2').value,
      shipping_city: this.customerForm.get('shipping_city').value,
      shipping_state: this.customerForm.get('shipping_state').value,
      shipping_zip: this.customerForm.get('shipping_zip').value,
      email: this.customerForm.get('email').value,
      phone_number: this.customerForm.get('phone_number').value,
      /* lat: 0,
      lng: 0, */
    };
    this.customer.phone_number.forEach(contactNumber => {
      this.phone_detail = contactNumber;
      if (this.phone_detail.phn != null) {
        if (this.phone_detail.phn.length == 10) {
          let temp = this.phone_detail.phn.substring(0, 3) + '-'
          temp += this.phone_detail.phn.substring(3, 6) + '-'
          temp += this.phone_detail.phn.substring(6)
          this.phone_detail.phn = temp;
        } else if (this.phone_detail.phn.length == 11) {
          let index = this.phone_detail.phn.lastIndexOf('-');
          if (index == 3) {
            let temp = this.phone_detail.phn.substring(0, 7) + '-'
            temp += this.phone_detail.phn.substring(7)
            this.phone_detail.phn = temp;
          } else if (index == 6) {
            let temp = this.phone_detail.phn.substring(0, 3) + '-'
            temp += this.phone_detail.phn.substring(3)
            this.phone_detail.phn = temp;
          }
        }
      }
    });

    if (this.customerForm.valid) {

      this._service.edit_cusomer('editcustomer', this.customer, this.customerid).subscribe(
        (response) => {
          this.closeModal();
          this.customer.email = response.data.customerdetail.email;
          this.info = this.customer;
          this.checkbox_val = this.info.same_as_billing
          this.isEditCustomerLoader = false
          this.collapseExpanDivArr.fill(true);
          this.alert.alert_msg('Customer details updated successfully!');
        },
        error => {
          if (error.status !== '401') {
            this.customer_error = error.error.errors;
          }

        }
      );


    }
    // stop here if form is invalid
    if (this.customerForm.invalid) {

      this.isEditCustomerLoader = false
      return;
    }

  }

  closeModal(): void {
    this.closeBtn.nativeElement.click();
    this.closePropBtn.nativeElement.click();
    this.closeDltEquipBtn.nativeElement.click();
    this.closeEditEquip.nativeElement.click();
    this.closeDltPropBtn.nativeElement.click();
    this.closeContractBtn.nativeElement.click();
    this.closeInspectionBtn.nativeElement.click();
    this.selectedequipmentindex = -1;
  }
  showInspectionDetails(id,i) {
    this.property_index=i;
    // console.log('this.properties:', this.properties);
    this.properties.forEach((element, index) => {
      if (element.id == id) {
        this.property_index=index;
        this.inspection_detail = element.property_inspections;
      }
    });

  }

  addProperty() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    localStorage.setItem('customerid', this.customerid);
    localStorage.setItem('customer_info', JSON.stringify(this.info));
    if (this.property_limit_left > 0) {
      const modalRef = this.modalService.open(AddPropertyComponent, ngbModalOptions);
    }
    else {
      this.alert.showErrorMessage("Property limit exceeded. Please upgrade your plan.");
    }
  }

  editProperty(id) {
    this.myFiles = []
    this.myFiles1 = []
    this.imagesname = ''
    this.createForm();
    this.contractfiles = ''
    this.property_id = id;
    let i = 0;
    let detail;
    this.no_of_inspection = []
    for (i = 0; i < this.properties.length; i++) {
      if (this.properties[i].id === id) {
        detail = this.properties[i];
      }
    }
    for (i = 1; i <= 30; i++) {
      this.no_of_inspection.push(i)
    }
    this.property_setVals(detail);
  }

  get fp() { return this.property.controls; }


  property_setVals(property_info) {
  
    this.years = []
    this.createEditInspectionDetail(property_info);
    this.contract_date = new Date(property_info.contract_date)
    this.contract_end_date = new Date(property_info.contract_end_date)
    this.start_year = this.contract_date.getFullYear()
    this.end_year = this.contract_end_date.getFullYear()
    while (this.start_year <= this.end_year) {
      this.years.push(this.start_year++)
    }

    this.property.patchValue(
      {
        building_name: property_info.building_name,
        address1: property_info.address1,
        address2: property_info.address2,
        city: property_info.city,
        state: property_info.state,
        zip_code: property_info.zip_code,
        contract_type: property_info.contract_type,
        contract_amount: this.addNumberFormat(property_info.contract_amount),
        contract_term: property_info.contract_term,
        contract_term_type: property_info.contract_term_type,
        contract_billing: property_info.contract_billing,
        contract_billing_custom: property_info.contract_billing_custom,
        contract_date: property_info.contract_date,
        contract_end_date: property_info.contract_end_date,
        inspectiondetail: property_info.property_inspections,
        no_of_inspections: property_info.no_of_inspections
      });

    this.inspection_detail_count = []
    for (let i = 0; i < property_info.no_of_inspections; i++)
      this.inspection_detail_count.push(i);

  }

  update_property_value() {
    this.isEditPropertyLoaderVisible = true
    this.property_submitted = true;
    if (this.property.valid) {
      let valid_dates = true
      let month = ''
      /*let inspectiondetailvalues = this.property.get('inspectiondetail').value
      inspectiondetailvalues.forEach(element => {
        console.log('element.service_month '+element.service_month);
        switch (element.service_month) {
          case "1":
            month = "January"
            break;
          case "2":
            month = "February"
            break;
          case "3":
            month = "March"
            break;
          case "4":
            month = "April"
            break;
          case "5":
            month = "May"
            break;
          case "6":
            month = "June"
            break;
          case "7":
            month = "July"
            break;
          case "8":
            month = "August"
            break;
          case "9":
            month = "September"
            break;
          case "10":
            month = "October"
            break;
          case "11":
            month = "November"
            break;
          case "12":
            month = "December"
            break;
          default:
            month = element.service_month
            break;
        }

        if (!isvalidDate(element.service_day, element.service_month, element.service_year)) {

          if (element.service_day != "" && element.service_day != null) {
            this.alert.showErrorMessage(month + "-" + element.service_year + " cannot have " + element.service_day + " days.");
            valid_dates = false
          }
        }
      });*/
      if (valid_dates) {
        this.contract_date = new Date(this.contract_date);
        this.contract_end_date = new Date(this.contract_end_date);
        var format_start_date = (this.contract_date.getMonth() + 1) + "/" + this.contract_date.getDate() + "/" + this.contract_date.getFullYear();
        var format_end_date = (this.contract_end_date.getMonth() + 1) + "/" + this.contract_end_date.getDate() + "/" + this.contract_end_date.getFullYear();
        const formData = new FormData();
        for (let i = 0; i < this.myFiles.length; i++) {
          formData.append('contract_file[]', this.myFiles[i]);
        }
        for (let i = 0; i < this.myFiles1.length; i++) {
          formData.append('propertyimages[]', this.myFiles1[i]);
        }
        // this.inspection_detail_count = []
        // for (let i = 0; i < this.property.no_of_inspections; i++)
        //   this.inspection_detail_count.push(i);
        formData.append('building_name', this.property.get('building_name').value);
        formData.append('address1', this.property.get('address1').value);
        formData.append('address2', this.property.get('address2').value);
        formData.append('city', this.property.get('city').value);
        formData.append('state', this.property.get('state').value);
        formData.append('zip_code', this.property.get('zip_code').value);
        formData.append('contract_type', this.property.get('contract_type').value);
        formData.append('contract_amount', this.clearNumberFormat(this.property.get('contract_amount').value));
        formData.append('contract_term', this.property.get('contract_term').value);
        formData.append('contract_term_type', this.property.get('contract_term_type').value);
        formData.append('contract_billing', this.property.get('contract_billing').value);
        formData.append('contract_billing_custom', this.property.get('contract_billing_custom').value);
        formData.append('contract_date', format_start_date);
        formData.append('contract_end_date', format_end_date);
        formData.append('propertyid', this.property_id);
        formData.append('customerid', this.customerid);
        formData.append('inspectiondetail', JSON.stringify(this.property.get('inspectiondetail').value));
        formData.append('no_of_inspections', this.property.get('inspectiondetail').value.length);


        this._service.property('editproperty', formData).subscribe(
          response => {
            this.editproperty = response.data.propertydetail
            for (this.i = 0; this.i < this.properties.length; this.i++) {
              if (this.properties[this.i].id === this.property_id) {
                this.properties[this.i].building_name = this.editproperty.building_name;
                this.properties[this.i].address1 = this.editproperty.address1;
                this.properties[this.i].address2 = this.editproperty.address2;
                this.properties[this.i].city = this.editproperty.city;
                this.properties[this.i].contract_type = this.editproperty.contract_type;
                this.properties[this.i].contract_amount = this.editproperty.contract_amount;
                this.properties[this.i].contract_date = this.editproperty.contract_date;
                this.properties[this.i].contract_end_date = this.editproperty.contract_end_date;
                this.properties[this.i].contract_term = this.editproperty.contract_term;
                this.properties[this.i].contract_term_type = this.editproperty.contract_term_type;
                this.properties[this.i].contract_billing = this.editproperty.contract_billing;
                this.properties[this.i].contract_billing_custom = this.editproperty.contract_billing_custom;
                this.properties[this.i].no_of_inspections = this.editproperty.no_of_inspections;
                //this.properties[this.i].inspectiondetail =  this.property.get('inspectiondetail').value; //this.property.get('inspectiondetail').value)response.data.propertydetail.property_inspections
                this.properties[this.i].property_inspections = response.data.propertydetail.property_inspections;
              }
            }
            this.isEditPropertyLoaderVisible = false
            this.alert.alert_msg('Property Updated Successfully.');
            this.closeModal();
          },
          error => {
            if (error.status !== '401') {
              this.property_error = error.error.errors
              this.alert.showErrorMessage(error.error.message);
            } else {
              this.closeModal();
            }
            this.isEditPropertyLoaderVisible = false
          });
      }
    }
    else {
      this.isEditPropertyLoaderVisible = false
      this.alert.alert_msg("Something went wrong, please try again.")
    }

  }
  deletePropertyId(id, index) {
    this.delete_property_id = id,
      this.deletePropertyIndex = index;
    //  data-toggle="modal" data-target="#confirm-delete-prop"
    this.closeModal();
  }
  deleteProperty() {
    // tslint:disable-next-line:max-line-length
    this._service.delete_property('deleteproperty', { customerid: this.customerid, propertyid: this.delete_property_id }).subscribe(
      response => {
        if (this.deletePropertyIndex >= 0) {
          this.properties.splice(this.deletePropertyIndex, 1);
        }
        this.property_limit_left++;
      },
      error => {
        this.alert.alert_msg("Please try again.")

      }
    );
    this.closeModal();
  }

  showEquipments(property_id, index) {
    this._service.get_equipments('propertydetail', { propertyid: property_id, customerid: this.customerid }).subscribe(
      response => {
        this.equipments = response.data.equipmentlist;
        this.collapseExpanDivArr = new Array(this.equipments.length);
        this.property_detail = true;
        this.propertydetail = response.data.propertydetail;
        this.propertylat = parseFloat(this.propertydetail.lat);
        this.propertylng = parseFloat(this.propertydetail.lng);
        this.property_index = index
      },
      error => {
        this.alert.alert_msg("Please try again.")
      }
    );
  }
  addEquipment(propertyid) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    localStorage.setItem('p_id', propertyid);
    localStorage.setItem('c_id', this.customerid);
    const modalRef = this.modalService.open(AddEquipmentComponent, ngbModalOptions);
  }

  /* editEquipment(id, propertyid) {
    localStorage.setItem('p_id', propertyid);
    localStorage.setItem('c_id', this.customerid);
    const modalRef = this.modalService.open(EditEquipmentComponent);
  } */


  showImage(e_id, p_id, c_id) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    localStorage.setItem('e_id', e_id);
    localStorage.setItem('p_id', p_id);
    localStorage.setItem('c_id', c_id);
    const modalRef = this.modalService.open(ShowEquipmentImageComponent, ngbModalOptions);
  }

  deleteEquipment(id, propertyid, customerid, index) {
    this.deleteEquipmentId = id;
    this.deleteEquipmentPropertyId = propertyid;
    this.deleteEquipmentCustomerId = customerid;
    this.deleteEquipmentPropertyIndex = index;
    //  data-toggle="modal" data-target="#confirm-delete-prop"
    this.closeModal();
  }
  deleteEquipmentConfirmed() {
    this._service.delete_Equipments('deleteequipment',
      { equipmentid: this.deleteEquipmentId, customerid: this.deleteEquipmentCustomerId, propertyid: this.deleteEquipmentPropertyId }
    ).subscribe(
      response => {
        if (this.deleteEquipmentPropertyIndex >= 0) {
          this.equipments.splice(this.deleteEquipmentPropertyIndex, 1);
          this._service.notification.emit(response.data.notificationarray)
          this.properties[this.property_index].property_service_due_status = response.data.duestatus.property_status
          this.alert.alert_msg("Equipment deleted successfully!")
        }
      },
      error => {
        this.alert.alert_msg("Please try again.")
      }
    );
    this.closeModal();
  }



  editEquipmentFunc(id, propertyid, customerid, equipmentindex) {
    this.equipment_id = id;
    this.property_id = propertyid;
    this.customerid = customerid;

    let i = 0;
    let detail;
    for (i = 0; i < this.equipments.length; i++) {
      if (this.equipments[i].id === id) {
        detail = this.equipments[i];
      }
    }
    this.selectedequipmentindex = equipmentindex;
    this.equipment_setVals(detail);
    // console.log(detail.filtersdetail[0].filter_size)
    if(!this.filter_sizes.includes(detail.filtersdetail[0].filter_size.toString())){
      this.editcustom_filter_flag=true;
      this.customvalue=detail.filtersdetail[0].filter_size;
    }

  }

  getFileDetails(e, value) {

    const regExp1 = new RegExp(/\.(jpg|jpeg|png)$/i)
    const regExp2 = new RegExp(/\.(jpg|jpeg|png|tif|pdf|exl|doc|xlsx)$/i)


    this.file = ''
    if (value == 'contract_file') {
      this.contractfiles = '';
      for (var i = 0; i < e.target.files.length; i++) {
        this.file = e.target.files[i];
        if (regExp2.test('' + this.file.name)) {
          this.myFiles.push(e.target.files[i]);
          this.contractfiles += this.file.name + '<BR />';

        }
        else {
          e.target.value = '';
          this.alert.showErrorMessage('Wrong file extension! File input is cleared.');
        }
      }
    }
    else if (value == 'equipment_image' || value == 'property_image') {
      this.myFiles1 = [];
      this.imagesname = '';
      for (var i = 0; i < e.target.files.length; i++) {
        this.file = e.target.files[i];
        if (regExp1.test('' + this.file.name)) {
          this.myFiles1.push(e.target.files[i]);
          this.imagesname += this.file.name + '<BR />';
        }
        else {
          this.isLoaderVisible = false;
          e.target.value = '';
          this.alert.showErrorMessage('Wrong file extension! File input is cleared.');
        }
      }
    }
  }
  get fe() {
    return this.equipment.controls;
  }
  equipment_setVals(equipment_info) {
    this.years = []
    this.imagesname = '';
    let additional_detail = ''


    if (equipment_info.aircondition_area == 'other') {
      this.equipment.controls.aircondition_area_other.setValidators([Validators.required])
    }
    if (equipment_info.additional_notes != null)
      additional_detail = equipment_info.additional_notes
    this.equipment.patchValue(
      {
        aircondition_area: equipment_info.aircondition_area,
        equipment: equipment_info.equipment,
        aircondition_area_other: equipment_info.aircondition_area_other,
        location_of_equipment: equipment_info.location_of_equipment,
        belt_size: equipment_info.belt_size,
        manufacturer_name: equipment_info.manufacturer_name,
        model_number: equipment_info.model_number,
        serial_number: equipment_info.serial_number,
        equipmentimages: '',
        additional_notes: additional_detail
      });
    if (equipment_info.filtersdetail.length > 0) {
      this.setFilter(equipment_info.filtersdetail)
    }
    else if (this.filter_size == undefined) {
      this.addFilter();
    }

  
    // this.inspection_detail_count = []
    // for (let i = 0; i < equipment_info.no_of_inspections; i++)
    //   this.inspection_detail_count.push(i);
    //alert('Air condition '+this.equipment.get('aircondition_area').value)
  }

  createEditInspectionDetail(info) {
    let items = this.property.get('inspectiondetail') as FormArray;
    let ij = 1;
    while (items.length > 0) {
      items.removeAt(ij)
    }
   
    var i = 0;
    let item;
    // console.log(info.property_inspections)
    info.property_inspections.forEach(val => {
    
      item = this.formBuilder.group({
        service_day: [val.service_day],
        service_month: [val.service_month, Validators.required],
        service_year: [val.service_year, Validators.required],
        inspection_type: [val.inspection_type],
        inspection_type_custom: [val.inspection_type_custom],
        labor_allotted: [val.labor_allotted]
      });
      items.push(item);
    });
    
    return items;
  }

  editEquipmentSubmit() {
    this.isEditEquipmentLoader = true
    this.edit_equiment_submitted = true;
    this.contractfiles = ''
    if (this.equipment.valid) {

      let index = 0;
      (this.equipment.get('filter_size').value).forEach(element => {

        if (element.filter_size == "" && index > 0) {
          (this.equipment.get('filter_size').value).splice(index, 1)
        }
        index = index + 1;
      });
      this.editEquipment = {
        aircondition_area: this.equipment.get('aircondition_area').value,
        aircondition_area_other: this.equipment.get('aircondition_area_other').value,
        equipment: this.equipment.get('equipment').value,
        location_of_equipment: this.equipment.get('location_of_equipment').value,
        filter_size: this.equipment.get('filter_size').value,
        belt_size: this.equipment.get('belt_size').value,
        manufacturer_name: this.equipment.get('manufacturer_name').value,
        model_number: this.equipment.get('model_number').value,
        serial_number: this.equipment.get('serial_number').value,
        equipmentimages: this.equipment.get('equipmentimages').value,
        additional_notes: this.equipment.get('additional_notes').value

      };

      const formData = new FormData();

      for (let i = 0; i < this.myFiles1.length; i++) {
        formData.append('equipmentimages[]', this.myFiles1[i]);
      }

      formData.append('aircondition_area', this.editEquipment.aircondition_area);
      formData.append('aircondition_area_other', this.editEquipment.aircondition_area_other);
      formData.append('equipment', this.editEquipment.equipment);
      formData.append('location_of_equipment', this.editEquipment.location_of_equipment);
      formData.append('belt_size', this.editEquipment.belt_size);
      formData.append('manufacturer_name', this.editEquipment.manufacturer_name);
      formData.append('model_number', this.editEquipment.model_number);
      formData.append('serial_number', this.editEquipment.serial_number);
      formData.append('additional_notes', this.editEquipment.additional_notes);
      formData.append('propertyid', this.property_id);
      formData.append('customerid', this.customerid);
      formData.append('equipmentid', this.equipment_id.toString());
 if(this.f_size_value == false){
        formData.append('filter_size', JSON.stringify(this.equipment.get('filter_size').value));
      }
      else{
       var value= '[{"id":"'+this.equipment.get('filter_size').value[0].id+'","filter_size":"'+this.equipment.get('filter_size_custom').value+'"}]';
        formData.append('filter_size', value);
      }
      // formData.append('filter_size', JSON.stringify(this.editEquipment.filter_size))

      this._service.equipment('editequipment', formData).subscribe(
        response => {
          this.collapseExpanDivArr.fill(false);
          this._service.notification.emit(response.data.notificationarray)
          this.properties[this.property_index].property_service_due_status = response.data.duestatus.property_status
          this.equipments[this.selectedequipmentindex].filtersdetail = response.data.equipmentdetail.filtersdetail

          let e_id = this.equipment_id;
          let edited_equipment = this.equipments.filter(function (element) {
            return element.id == e_id;
          });
          this.editEquipment.filter_size[0]['filter_size'] = response.data.equipmentdetail.filtersdetail
          if (this.editEquipment.filter_size.length != 0) {
            edited_equipment[0].filter_size = this.editEquipment.filter_size[0]['filter_size']
          }
          edited_equipment[0].aircondition_area = this.editEquipment.aircondition_area
          edited_equipment[0].aircondition_area_other = this.editEquipment.aircondition_area_other
          edited_equipment[0].equipment = this.editEquipment.equipment
          edited_equipment[0].belt_size = this.editEquipment.belt_size
          edited_equipment[0].manufacturer_name = this.editEquipment.manufacturer_name
          edited_equipment[0].model_number = this.editEquipment.model_number
          edited_equipment[0].serial_number = this.editEquipment.serial_number
          edited_equipment[0].location_of_equipment = this.editEquipment.location_of_equipment
          edited_equipment[0].equipmentinspections = response.data.equipmentdetail.equipmentinspections
          edited_equipment[0].additional_notes = this.editEquipment.additional_notes
          this.isEditEquipmentLoader = false
          this.alert.alert_msg('Equipment Updated Successfully.');
          this.closeModal();
        },
        error => {
          this.isEditEquipmentLoader = false
          if (error.status != '401') {
            this.equipment_error = error.error.errors
            this.alert.showErrorMessage(error.error.message);
          }
          else {
            this.closeModal();
          }
        });

    }
    else {
      this.isEditEquipmentLoader = false
      this.alert.showErrorMessage('Please provide valid values.');
    }
    this.edit_equiment_submitted = false;
  }

  inspectionDetails(e) {
    this.years = []
    var i = 0;
    var increement = false;
    var decreement = false;
    var startIndex = 0;
    var removeItems = 0;
    var newInspectionCount = e.target.value;
    let items = this.property.get('inspectiondetail') as FormArray;

    if (items.length > 0 && newInspectionCount > items.length) {
      increement = true;
      startIndex = items.length;
    } else if (items.length > 0 && newInspectionCount < items.length) {
      decreement = true;
    } else {
      increement = true;
    }
    if (increement) {
      let item;
      for (i = startIndex; i < newInspectionCount; i++) {
        this.inspection_detail_count.push(i)
        item = this.formBuilder.group({
          service_day: '',
          service_month: ['', Validators.required],
          service_year: ['', Validators.required],
          inspection_type: [''],
          inspection_type_custom: [''],
          labor_allotted: ['']
        });
        items.push(item);
      }
    } else if (decreement) {
      let len = items.length;
      while (items.length > newInspectionCount) {
        items.removeAt(--len)
        this.inspection_detail_count.pop();
      }
    }

    this.contract_date = new Date(this.contract_date);
    this.contract_end_date = new Date(this.contract_end_date);
    this.start_year = this.contract_date.getFullYear()
    this.end_year = this.contract_end_date.getFullYear()
    while (this.start_year <= this.end_year) {
      this.years.push(this.start_year++)
    }
  }

  goToCustomerList() {
    this.router.navigate(['/customer'])
  }

  toggleMap1Visibility() {
    this.show_map1 = !this.show_map1
  }
  toggleMap2Visibility() {
    this.show_map2 = !this.show_map2
  }
filter_change(e){
  if(e.target.value=="Custom"){
    this.f_size_value=true;
  }
  else
  {
    this.f_size_value=false;
    this.editcustom_filter_flag=false;
  }
}
  removeFilterSize(index, id) {
    if (id != 0) {
      this._service.delete_filter('deleteequipmentfilter', { equipmentid: this.equipment_id, filterid: id }).subscribe(
        response => {
          if (index >= 0) {
            if (this.selectedequipmentindex >= 0) {
              this.equipments[this.selectedequipmentindex].filtersdetail.splice(index, 1);
              //this.selectedequipmentindex = -1;
            }
          }

          this.removeFromArray(index)
        },
        error => {
          this.alert.alert_msg("Please try again.")
        })
    }
    else {
      this.removeFromArray(index)
    }
  }

  removeFromArray(index) {
    const control = <FormArray>this.equipment.controls['filter_size']
    control.removeAt(index);
  }
  setFilter(filter) {
    const filters = [];
   
    filter.forEach(size => {
      var value=size.filter_size;
      if(size.filter_size=='Custom'){
        this.f_size_value=true;
      }
      else{
        this.f_size_value=false
      }
      
      filters.push(this.formBuilder.group({
        id: [size.id],
        filter_size: [value]
      }));
    });
    this.equipment.setControl('filter_size', this.formBuilder.array(filters));
  }

  addFilter(): void {
    this.filter_size = this.equipment.get('filter_size') as FormArray;
    this.filter_size.push(this.createFilter());
  }

  createFilter(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      filter_size: ''
    });
  }
  setInspectionDetails(details) {

    if (details) {
      const inspection_details = [];
      details.forEach(date => {
        inspection_details.push(this.formBuilder.group({
          service_day: [date.service_day],
          service_month: [date.service_month],
          service_year: [date.service_year],
          inspection_type: [date.inspection_type],
          inspection_type_custom: [date.inspection_type_custom],
          labor_allotted: [date.labor_allotted]
        }));
      });
      this.equipment.setControl('equipmentinspections', this.formBuilder.array(inspection_details));

    }
  }
  // inspectionDetails(e){
  //   var i=0
  //   for(i=0;i<e.target.value;i++){
  //     this.addInspectionDetail()
  //   }
  // }
  addInspectionDetail(): void {
    this.equipmentinspections = this.equipment.get('equipmentinspections') as FormArray;
    this.equipmentinspections.push(this.createInspectionDetail())
  }
  createInspectionDetail(): FormGroup {
    return this.formBuilder.group({
      service_day: '',
      service_month: ['', Validators.required],
      service_year: ['', Validators.required],
      inspection_type: [''],
      inspection_type_custom: [''],
      labor_allotted: ['']
    })
  }
  selectionChanged(e) {
    if (e.target.value == 'other') {
      this.equipment.controls.aircondition_area_other.setValidators([Validators.required])
    }
    else {
      this.equipment.controls.aircondition_area_other.clearValidators()
      this.equipment.controls.aircondition_area_other.updateValueAndValidity();
    }
  }

  showPropertyImage(id) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    localStorage.setItem("PropertyId", id)
    const modalRef = this.modalService.open(ShowPropertyImageComponent, ngbModalOptions);
  }
  showContract(id) {
    this.property_id = id
    this._service.showContract('propertycertificate', { propertyid: this.property_id }).subscribe(
      response => {
        this.cotractfiles = response.data.propertycertificateslist
      },
      error => {
        this.alert.showErrorMessage("Something went wrong, please try again.")
        this.closeModal()
      }

    )
  }
  downloadContract(id) {
    this.download_url = environment.apiUrl + 'downloadpropertycontract?propertyid=' + this.property_id + '&contractid=' + id
  }
  deleteContract(id, name, index) {
    this.showConfirmDialog(id, name, index)
  }
  deleteFile(id, name, index) {

    this._service.deleteContract('deletepropertycontract', { propertyid: this.property_id, contractid: id, contractname: name }).subscribe(
      response => {
        this.cotractfiles.forEach(element => {
          if (element.id == id) {
            this.cotractfiles.splice(index, 1)
          }
        });
      },
      error => {
        this.alert.showErrorMessage("Something went wrong, please try again.")
      })
  }
  showConfirmDialog(id, filename, index) {
    swal("Are you sure you want to do this?", {
      buttons: {
        Delete: {
          value: "catch",
        },
        Cancel: true,
      },

    })
      .then((value) => {
        switch (value) {

          case "catch":
            this.deleteFile(id, filename, index)
            break;
        }

      });
  }

  toggleCollapseIcon(id, curlogoclass, index) {

    if (curlogoclass == 'add')
      this.collapseExpanDivArr[index] = true;
    else
      this.collapseExpanDivArr[index] = false;
  }
  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  gotoProperty() {
    // this.show_map2=false
    this.property_index = -1
    this.property_detail = !this.property_detail;
  }
  markCompleted(propertyid, index, id, date_index) {
    const detail = { equipmentid: '0', propertyid: propertyid, customerid: this.customerid, equipment_status: '1', equipmentinspectionid: id }
    this._service.markcompleted('changeequipmentstatustocomplete', detail).subscribe(
      response => {
        this._service.notification.emit(response.data.notificationarray)
        // this.equipments[index].equipmentinspections[date_index].service_due_status = 1
        this.properties[this.property_index].property_service_due_status = response.data.statusdetail.property_status
        this.inspection_detail[date_index].service_due_status="1";
      },
      error => {
        this.alert.alert_msg("Please try again.")
      })
  }
  markDue(propertyid, index, id, date_index) {
    const detail = { equipmentid: '0', propertyid: propertyid, customerid: this.customerid, equipment_status: '2', equipmentinspectionid: id }
    this._service.markcompleted('changeequipmentstatustocomplete', detail).subscribe(
      response => {
        this._service.notification.emit(response.data.notificationarray)
        
        this.properties[this.property_index].property_service_due_status = response.data.statusdetail.property_status
       
        this.inspection_detail[date_index].service_due_status="2";
      },
      error => {
        this.alert.alert_msg("Please try again.")
      })
  }

}
