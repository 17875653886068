import { Component, OnInit } from '@angular/core';
import { Services } from '../services/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Alert } from '../Helpers/alert';

@Component({
  selector: 'app-show-property-image',
  templateUrl: '../views/show-property-image.component.html'
})
export class ShowPropertyImageComponent implements OnInit {
  isLoaderVisible = false
  frameimage: any;
  image_name = ''
  imageid = ''
  images: any;
  propertyid = ''
  deleteIndex = -1;
  constructor(private _service: Services, private activemodal: NgbActiveModal, private alert: Alert) { }

  ngOnInit() {
    this.isLoaderVisible = true
    this.propertyid = localStorage.getItem("PropertyId")
    this._service.showpropertyimage('propertyimages', { propertyid: this.propertyid }).subscribe(
      response => {
        this.isLoaderVisible = false
        if (response.data.image_found === '1') {
          this.images = response.data.propertyimageslist;
          this.frameimage = this.images[0].image_path;
        } else if (response.data.image_found === '0') {
          this.activemodal.close();
          this.alert.alert_msg(response.message);
        }
      },
      error => {
        this.isLoaderVisible = false
        this.alert.alert_msg("Something went wrong, please try again.")
        this.closeModal()
      }
    )
  }
  closeModal() {
    localStorage.removeItem('PropertyId');
    this.activemodal.close();
  }

  showConfirmDialog(id, imagename, index) {
    swal("Are you sure you want to do this?", {
      buttons: {
        Delete: {
          value: "catch",
        },
        Cancel: true,
      },

    })
      .then((value) => {
        switch (value) {

          case "catch":
            this.deleteIndex = index;
            this.image_name = imagename
            this.imageid = id
            this.deletePropertytImage()
            break;
        }

      });

  }
  deletePropertytImage() {
    this._service.delete_Equipment_image('deletepropertyimage', {
      imageid: this.imageid,
      propertyid: this.propertyid,
      imagename: this.image_name,
    }).subscribe(
      response => {
        this.alert.alert_msg('Image removed successfully.');
        if (this.deleteIndex >= 0) {
          this.images.splice(this.deleteIndex, 1);
          if (this.images[0]) {
            this.frameimage = this.images[0]['image_path'];
            this.deleteIndex = -1;
          }
          else {
            this.closeModal()
          }
        }
      },
      error => {
        this.alert.alert_msg('Something went wrong, please try again.');
      }
    );

  }
  changeframeimage(src) {
    this.frameimage = src;
  }
}
