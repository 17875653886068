import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Alert } from '../Helpers/alert';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  token = ''
  subscription_status = ''

  constructor(private router: Router, private alert: Alert) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let url = "";
    this.subscription_status = localStorage.getItem('subscription_status')
    
    if (route.url[0].path == "customerdetail") {
      url = "customer"
    }
    else {
      url = route.url[0].path
    }
    localStorage.setItem("active_option", url)
    if (this.token != null) {
      if (this.subscription_status == '1' && route.url[0].path == 'addsubscription') {
        this.router.navigate(['/customer']);
        return true;
      }
      else if (this.subscription_status == '3'){
        localStorage.clear();
        this.router.navigate(['/login']);  
        this.alert.alert_process_msg("Your request is under process,please try after 30 minutes.");
        return true;
      }
      else if (this.subscription_status == '0' && !(window.location.href.includes('executeagreement')) && route.url[0].path !== 'addsubscription') {
        this.router.navigate(['/addsubscription'])
        return true
      }
      else if (this.subscription_status == '2' && route.url[0].path !== 'subscription') {
        this.alert.showErrorMessage("Your subscription is expired. Please renew or upgrade your plan.")
        this.router.navigate(['/subscription'])
        return true
      }
      return true;
    } else {
      localStorage.clear()
      this.router.navigate(['/login'])
      return false
    }
  }
}
