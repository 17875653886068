import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Services } from '../services/services';
import { Customer } from '../models/customer';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Alert } from '../Helpers/alert';
import { validatePhnNumber, validatePhone } from '../Helpers/validations';
@Component({
  selector: 'app-add-customer',
  templateUrl: '../views/add-customer.component.html'
})
export class AddCustomerComponent implements OnInit {
  isLoaderVisible = false
  marked = false
  customerForm: FormGroup;
  email: FormArray
  phone_number: FormArray
  submitted = false;
  customer: Customer
  index = 0;
  temp = 0;
  error: {
    first_name,
    last_name,
    company_name,
    billing_address1,
    billing_address2,
    billing_city,
    billing_state,
    billing_zip,
    same_as_billing,
    shipping_address1,
    shipping_address2,
    shipping_city,
    shipping_state,
    shipping_zip,
    email,
    phone_number
  };
  constructor(private alert: Alert, private formBuilder: FormBuilder,
    private _service: Services, private active_modal: NgbActiveModal,
    // private dialogRef: MatDialogRef<AddCustomerComponent>
  ) {
    // dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.customerForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: '',
      company_name: '',
      billing_address1: '',
      billing_address2: '',
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      same_as_billing: 'false',
      shipping_address1: '',
      shipping_address2: '',
      shipping_city: '',
      shipping_state: '',
      shipping_zip: '',
      email: this.formBuilder.array([this.createItem('email')]),
      phone_number: this.formBuilder.array([this.createItem('phn')]),
    });
  }


  validateNumber(num) {
    if (!validatePhnNumber(num)) {
      return true
    }
  }

  valueChanged(e) {
    var phn_val: string = e.target.value
    var num = phn_val.charAt(phn_val.length - 1)
    if (this.validateNumber(num))
      e.target.value = phn_val.substring(0, phn_val.length - 1)
    else if (phn_val.length == 3 || phn_val.length == 7)
      e.target.value = phn_val + "-"

  }

  createItem(val): FormGroup {
    if (val === 'email') {
      return this.formBuilder.group({
        mail: ['', Validators.email]
      });

    } else if (val === 'phn') {
      return this.formBuilder.group({
        phn: ['', validatePhone],
        type: '',
        contact_name: ''
      });
    }
  }
  addEmail(): void {
    this.email = this.customerForm.get('email') as FormArray;
    this.email.push(this.createItem('email'));
  }
  removeUnit(i: number, val) {
    const control = <FormArray>this.customerForm.controls[val];
    control.removeAt(i);
  }
  addPhn() {
    this.phone_number = this.customerForm.get('phone_number') as FormArray;
    this.phone_number.push(this.createItem('phn'));
  }

  toggleVisibility(e) {
    this.marked = e.target.checked;
    if (!this.marked) {
      this.customerForm.controls.shipping_address1.setValue('');
      this.customerForm.controls.shipping_address2.setValue('');
      this.customerForm.controls.shipping_city.setValue('');
      this.customerForm.controls.shipping_state.setValue('');
      this.customerForm.controls.shipping_zip.setValue('');
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.customerForm.controls; }

  closeModal() {
    this.active_modal.close();
  }

  onSubmit() {
    // console.log((this.customerForm.get('email').value))
    this.isLoaderVisible = true
    if (this.customerForm.get('same_as_billing').value) {
      this.customerForm.controls.shipping_address1.setValue(this.customerForm.get('billing_address1').value);
      this.customerForm.controls.shipping_address2.setValue(this.customerForm.get('billing_address2').value);
      this.customerForm.controls.shipping_city.setValue(this.customerForm.get('billing_city').value);
      this.customerForm.controls.shipping_state.setValue(this.customerForm.get('billing_state').value);
      this.customerForm.controls.shipping_zip.setValue(this.customerForm.get('billing_zip').value);
    }
    this.submitted = true;
    let index = 0;
    (this.customerForm.get('email').value).forEach(element => {
      if (element.mail == "" && index > 0) {
        (this.customerForm.get('email').value).splice(index, 1)
      }
      index = index + 1;
    });
    index = 0;
    (this.customerForm.get('phone_number').value).forEach(element => {
      if (element.phn == "" && index > 0) {
        (this.customerForm.get('phone_number').value).splice(index, 1)
      }
      index = index + 1;
    });
    this.customer = {
      first_name: this.customerForm.get('first_name').value,
      last_name: this.customerForm.get('last_name').value,
      company_name: this.customerForm.get('company_name').value,
      billing_address1: this.customerForm.get('billing_address1').value,
      billing_address2: this.customerForm.get('billing_address2').value,
      billing_city: this.customerForm.get('billing_city').value,
      billing_state: this.customerForm.get('billing_state').value,
      billing_zip: this.customerForm.get('billing_zip').value,
      same_as_billing: this.customerForm.get('same_as_billing').value,
      shipping_address1: this.customerForm.get('shipping_address1').value,
      shipping_address2: this.customerForm.get('shipping_address2').value,
      shipping_city: this.customerForm.get('shipping_city').value,
      shipping_state: this.customerForm.get('shipping_state').value,
      shipping_zip: this.customerForm.get('shipping_zip').value,
      email: this.customerForm.get('email').value,
      phone_number: this.customerForm.get('phone_number').value
    };
    if (this.customerForm.valid) {
      this._service.add_customer('addcustomer', this.customer).subscribe(
        (response) => {

          this.isLoaderVisible = false
          this.closeModal();
          this._service.addCustomerEmitter.emit({ customerdetail: response.data.customerdetail, totalcustomers: response.data.totalcustomers });
          this.alert.alert_msg('Customer details added successfully!');
        },
        error => {
          this.isLoaderVisible = false
            ;
          if (error.status !== '401') {
            this.error = error.error.errors;
            ;
          }
        }
      );
    }
    // stop here if form is invalid
    if (this.customerForm.invalid) {
      this.isLoaderVisible = false
      return;
    }


  }

}
