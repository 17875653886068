import { Component, OnInit } from '@angular/core';
import { Services } from '../services/services';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Alert } from '../Helpers/alert';


@Component({
  selector: 'app-subscription',
  templateUrl: '../views/subscription.component.html'
})
export class SubscriptionComponent implements OnInit {
  _message = 'Subscription';
  plans_array = [];
  active_plan_array = [];
  isLoaderVisible = false
  constructor(private _service: Services, private modalService: NgbModal, private alert:Alert ) {
    this.subscribeSubscriptionPlan(this);
  }

  ngOnInit() {
    this.isLoaderVisible = true
    this.userSubscriptionDetail();
  }

  userSubscriptionDetail() {
    this._service.loadPackageList('usersubscription').subscribe(
      (response) => {
        this.isLoaderVisible = false
        this.active_plan_array = response.data.user_plan;
        this.plans_array = response.data.subscrionplans_list;
      },
      error => {
        this.isLoaderVisible = false
        if (error.status !== '401') { }
      }
    );
  }

  onClick(plan) {
    this.isLoaderVisible = true;
    // console.log(plan.id);
    // localStoage.setItem('selected_plan', JSON.stringify(plan));
    this._service.get_url('upgradesubscriptionplan', { subs_plan_id: plan.id }).subscribe(
      response => {
        window.location.href=response.data.approvalUrl;
        this.isLoaderVisible = false;
      },
      error => {
        this.isLoaderVisible = false;
        if (error.status !== '401') {
          this.alert.showErrorMessage('Something went wrong please try again!');
        }
      }
    )
    // let ngbModalOptions: NgbModalOptions = {
    // backdrop: 'static',
    // keyboard: false
    // };
    // const modalRef = this.modalService.open(PaymentComponent, ngbModalOptions);
  }
  subscribeSubscriptionPlan(instance) {
    this._service.subscriptionPlanEmitter.subscribe(function (value) {
      instance.userSubscriptionDetail();
      localStorage.removeItem('selected_plan');
    });
  }

}
